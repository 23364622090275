import React, { useState, useEffect } from "react";
import { valueCheckGlobalForFinancials } from "../../../GlobalFunctions";
import { useCookies } from "react-cookie";
import LoadingImage from "../../../assets/media/loader.gif";
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
  modal_excel_icon,
} from "../../../api/baseUrl";
import { Link, useLocation } from "react-router-dom";
import Header from "../../../common/header/header";
import Search2 from "../search2";
import Nav from "../nav";
import { SegmentedControl } from "@mantine/core";
import ComparisonChartModal from "../../../layout/modals/ComparisonChartModal";
import * as XLSX from "xlsx";

const BalanceTable = (props) => {
  const location = useLocation();
  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});
  const [bs, setBs] = useState([]);
  const [valData, setValData] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [label, setLabel] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [chartData, setchartData] = useState([]);
  const [value, setValue] = useState("annual");
  const [slice, setSlice] = useState(-11);
  const [colspan, setColspan] = useState(15);

  useEffect(() => {
    fetch(`${baseUrl}/bs/${companyId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBs(data);
        setValData(data.annual);
        setIsLoading(true);
        console.log(data);
      });
  }, [companyId]);

  const handleChartModal = (company_name, label, chartData) => {
    setCompanyName(company_name);
    setLabel(label + " - PKR(mn)");
    setchartData(chartData);
    setChartActive(true);
  };

  const handleSwitch = (value) => {
    setColspan(15);
    setSlice(11);
    setValue(value);
    setValData(bs[value]);
  };
  const excel_name =
    company.name +
    ` - (${
      value.charAt(0).toUpperCase() + value.slice(1)
    }) Balance Sheet - PKR (mn)`;

  const handleExport = () => {
    const table = document.getElementById("table");
    const wb = XLSX.utils.table_to_book(table, { sheet: "file" });
    XLSX.writeFile(wb, `${excel_name}.xlsx`);
  };

  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {isLoading ? (
        <>
          <div className="dashboard-main container container_box">
            <div className="table-data-format">
              <div className="row" style={(styles.companyname, styles.lables)}>
                <div className="col-sm" style={styles.companylogoimage}>
                  <div style={styles.lables}>
                    <SegmentedControl
                      value={value}
                      onChange={handleSwitch}
                      data={[
                        { label: "Annual", value: "annual" },
                        { label: "Quarterly", value: "quarter" },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <span style={styles.excel}>
                <button
                  onClick={handleExport}
                  filename={excel_name}
                  className="custom-export-button download-table-xls-button income-excel-btn"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: modal_excel_icon,
                    }}
                  />
                </button>
              </span>
            </div>
            <div className="ask-table-format ask-top-space">
              <table className="table table-responsive bs-table" id="table">
                <thead>
                  <tr>
                    <th colSpan={2}>Balance Sheet - PKR (mn)</th>
                    <th>&nbsp;</th>
                    <th className="no-export excludeExport">&nbsp;</th>
                    {valData[0].data[0].data.slice(-11).map((y, i) =>
                      i != valData[0].data[0].data.slice(-11).length ? (
                        <th key={i} className="right-align">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: y.year,
                            }}
                          />
                        </th>
                      ) : null
                    )}
                  </tr>
                </thead>
                <tbody>
                  {valData.map((main_heading, i) => {
                    var bold = main_heading.bold == 1 ? "800" : "0";
                    return (
                      <React.Fragment key={i}>
                        <tr className="bg-td bold">
                          <td colSpan={colspan}>{main_heading.label}</td>
                        </tr>
                        {main_heading.data.map((sub_heading, i) => {
                          var abc = sub_heading.data.map(function (el) {
                            return el.value;
                          });

                          return (
                            <tr
                              key={i}
                              className={sub_heading.bold == 1 ? "bold" : ""}
                            >
                              <td colSpan={2}>{sub_heading.label}</td>
                              <td>
                                <span
                                  style={styles.chart}
                                  onClick={() => {
                                    handleChartModal(
                                      company.name,
                                      sub_heading.label,
                                      sub_heading.data
                                    );
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: modal_chart_icon,
                                    }}
                                  />
                                </span>

                                <span style={styles.chart}>
                                  <Link
                                    // target="_blank"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open(
                                        e.currentTarget.href,
                                        "_blank",
                                        "location = yes,height = 450,width = 950,scrollbars = yes,status = yes,left = 200),top = 100"
                                      );
                                    }}
                                    to={{
                                      pathname: `/comparison/balancesheet/${value}/${company.symbol}/${sub_heading.description_id}`,
                                    }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: modal_table_icon,
                                      }}
                                    />
                                  </Link>
                                </span>
                              </td>

                              <td className="no-export excludeExport">
                                <img
                                  src={`https://quickchart.io/chart?bkg=transparent&c={type:%27sparkline%27,data:{datasets:[{backgroundColor:%20%27rgba(24,183,21,%200.1)%27,fill:true,borderWidth:8,borderColor:%27rgb(24,183,21)%27,data:[${abc.toString()}]}]}}`}
                                  width="50px"
                                  height="20px"
                                />
                              </td>
                              {sub_heading.data
                                .slice(-11)
                                .map((d, j) =>
                                  j != sub_heading.data.slice(-11).length ? (
                                    <td key={j} className="right-align">
                                      {valueCheckGlobalForFinancials(d.value)}
                                    </td>
                                  ) : null
                                )}
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {chartActive && (
            <ComparisonChartModal
              chartActive={chartActive}
              setChartActive={setChartActive}
              companyname={companyName}
              label={label}
              chartData={chartData}
              slice={false}
            />
          )}
        </>
      ) : (
        <>
          <div
            style={{ width: "100%", height: "100%", color: "#426fd7" }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img src={LoadingImage} style={{ height: "500px" }} />
          </div>
        </>
      )}
    </>
  );
};

export default BalanceTable;

const styles = {
  emptyRow: {
    width: "0.1px",
    border: "none !important",
  },
  chart: {
    cursor: "pointer",
    float: "left",
    posiiton: "absolute",
    padding: "0px 10px 0 0px",
  },
  switchBtn: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    transform: "scale(1.5)",
    marginRight: "23px",
  },
  lables: {
    float: "right",
    textAlign: "right",
  },
  excel: {
    cursor: "pointer",
    float: "right",
  },
};
