import React, { useEffect, useState } from 'react';
import LoadingImage from '../../../assets/media/loader.gif';
import Nav from '../nav';
import Header from '../../../common/header/header';
import {
  modal_chart_icon,
  modal_excel_icon,
  baseUrl,
} from '../../../api/baseUrl';
import { SegmentedControl, Loader as MaintineLoader } from '@mantine/core';
import { YearPickerInput } from '@mantine/dates';
import { useCookies } from 'react-cookie';
import ComparisonChartModal from '../../../layout/modals/ComparisonChartModal';
import { valueCheckGlobal } from '../../../GlobalFunctions';

const Inflation = (props) => {
  const [datavalue, setDataValue] = useState('monthlyinflation');
  const [indicesvalue, setIndices] = useState('indices');
  const [periodvalue, setPeriodValue] = useState('monthly');
  const [parametervalue, setParameter] = useState('growth');
  const [parameterchangevalue, setParameterValue] = useState('MoM');
  const [cpi, setCpi] = useState([]);
  const [cpiKeys, setCpiKeys] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [label, setLable] = useState('');
  const [chartData, setchartData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    fetch(`${baseUrl}/sectordata/inflation/cpi`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCpi(data);
        setCpiKeys(data);
        setIsLoading(true);
      });
  }, []);

  useEffect(() => {
    if (indicesvalue == 'indices') {
      setParameter('growth');
    } else {
      setParameter('value');
    }
  }, [indicesvalue]);

  const handleChartModal = (type, label, chartData) => {
    setCompanyName(type);
    setLable(type + ' - ' + label);
    setchartData(chartData);
    setChartActive(true);
  };

  return (
    <>
      <Header />
      <div className="inflation-main analyst">
        <div className="row omcboxed" style={styles.navWidth}>
          <Nav nopadding={true} />
        </div>
        <div className="omc-view container container_box">
          <div className="row m-auto">
            <div className="col-md-5 pl-0">
              <div className="view-type">
                <span>Step 1: Select Data Source</span>
              </div>
              <div className="view-tabs-box radio_data1 radio_tabs12">
                <SegmentedControl
                  value={datavalue}
                  onChange={setDataValue}
                  data={[
                    {
                      label: 'Monthly Inflation Statistics',
                      value: 'monthlyinflation',
                    },
                    { label: 'Weekly SPI', value: 'weeklySPI' },
                  ]}
                />
              </div>
            </div>
            <div className="col-md-3 pl-0">
              <div className="view-type">
                <span>Step 2: Indices vs Prices</span>
              </div>
              <div className="view-tabs-box radio_data1 radio_tabs12">
                <SegmentedControl
                  value={indicesvalue}
                  className="Entity-dropdown"
                  onChange={setIndices}
                  data={[
                    {
                      label: 'Indices',
                      value: 'indices',
                    },
                    { label: 'Prices', value: 'prices' },
                  ]}
                />
              </div>
            </div>
            <div className="col-md-4 pl-0">
              <div className="view-type">
                <span>Step 3: Select Date / Period Range</span>
              </div>
              <div className="view-tabs-box1 radio_data1 radio_tabs12 Selection_tab inflation-year-picker">
                <YearPickerInput
                  type="range"
                  placeholder="Pick dates range"
                ></YearPickerInput>
              </div>
            </div>
            <div className="col-md-5 pl-0 fertilizer-box-top">
              <div className="view-type">
                <span>Step 4: Select Period</span>
              </div>
              <div className="view-tabs-box radio_data1 radio_tabs12">
                <SegmentedControl
                  value={periodvalue}
                  onChange={setPeriodValue}
                  data={[
                    { label: 'Weekly', value: 'weekly', disabled: true },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Quarterly', value: 'quarterly' },
                    { label: 'Half Yearly', value: 'halfyearly' },
                    { label: 'CY', value: 'CY' },
                    { label: 'FY', value: 'FY' },
                  ]}
                />
              </div>
            </div>
            <div className="col-md-7 pl-0 fertilizer-box-top">
              <div className="view-type">
                <span>Step 5: Select Parameter</span>
              </div>
              <div className="view-tabs-box1 radio_data1 radio_tabs12 select_parameter">
                <SegmentedControl
                  value={parametervalue}
                  onChange={setParameter}
                  readOnly
                  data={[
                    { label: 'Value', value: 'value', disabled: false },
                    { label: 'Growth', value: 'growth', disabled: false },
                  ]}
                />

                <SegmentedControl
                  value={parameterchangevalue}
                  onChange={setParameterValue}
                  data={[
                    { label: 'YOY', value: 'YOY', disabled: true },
                    { label: 'MoM', value: 'MoM' },
                    { label: 'WoW', value: 'WoW', disabled: true },
                    { label: 'QoQ', value: 'QoQ', disabled: true },
                    { label: 'HoH', value: 'HoH', disabled: true },
                  ]}
                />
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="omc-view-txt inflation-data">
              <div className="omc-volume">
                <div className="omc-excel-volume inflation-excel-right">
                  <div className="omc-excel-down">
                    <span>
                      Download
                      <div
                        dangerouslySetInnerHTML={{ __html: modal_excel_icon }}
                      />
                    </span>
                  </div>
                </div>
                <div className="ask-table-format">
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th></th>
                        <th></th>
                        <th className="right-align">Group Weight (%)</th>
                        {cpi[0].data.map((y, i) => (
                          <th className="right-align" key={i}>{y.year}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {cpi.map((value) => {
                        return (
                          <>
                            <TableRow
                              value={value}
                              handleChartModal={handleChartModal}
                              expandedRows={expandedRows}
                              setExpandedRows={setExpandedRows}
                              Step={'First'}
                            />

                            {expandedRows[value.row] &&
                              value.second.map((second) => {
                                return (
                                  <>
                                    <TableRow
                                      value={second}
                                      handleChartModal={handleChartModal}
                                      expandedRows={expandedRows}
                                      setExpandedRows={setExpandedRows}
                                      Step={'Second'}
                                    />

                                    {'Third' in second &&
                                      expandedRows[second.row] &&
                                      second.Third.map((third) => {
                                        return (
                                          <>
                                            <TableRow
                                              value={third}
                                              handleChartModal={
                                                handleChartModal
                                              }
                                              expandedRows={expandedRows}
                                              setExpandedRows={setExpandedRows}
                                              Step={'Third'}
                                            />
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{ width: '100%', height: '100%', color: '#426fd7' }}
              className="main-loader d-flex flex-column justify-content-center align-items-center"
            >
              <img src={LoadingImage} style={{ height: '500px' }} />
            </div>
          )}
        </div>
      </div>

      {chartActive && (
        <ComparisonChartModal
          chartActive={chartActive}
          setChartActive={setChartActive}
          companyname={companyName}
          label={label}
          chartData={chartData}
          slice={false}
        />
      )}
    </>
  );
};

const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '240px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '50%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};

const TableRow = ({
  value,
  handleChartModal,
  expandedRows,
  setExpandedRows,
  Step,
}) => {
  var abc = value.data.map(function (el) {
    return el.value;
  });

  const handleRowClick = (rowId) => {
    console.log(rowId);
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));
  };
  return (
    <>
      <tr>
        <td
          onClick={() => handleRowClick(value.row)}
          style={{ cursor: 'pointer' }}
          className={
            Step == 'Second'
              ? 'pd-left'
              : Step == 'Third'
              ? 'expanded-pd-left'
              : ''
          }
        >
          {Step == 'First' ? (
            <span
              className={`arrow ${expandedRows[value.row] ? 'down' : ''}`}
            ></span>
          ) : null}
          {Step == 'Second' && 'Third' in value ? (
            <span
              className={`arrow ${expandedRows[value.row] ? 'down' : ''}`}
            ></span>
          ) : null}
          {value.label}
        </td>
        <td>
          <div className="chart-omc-row">
            <span
              style={styles.chart}
              onClick={() => {
                handleChartModal(value.source, value.label, value.data);
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: modal_chart_icon,
                }}
              />
            </span>
          </div>
        </td>
        <td className="right-align">
          <img
            src={`https://quickchart.io/chart?bkg=transparent&c={type:%27sparkline%27,data:{datasets:[{backgroundColor:%20%27rgba(24,183,21,%200.1)%27,fill:true,borderWidth:8,borderColor:%27rgb(24,183,21)%27,data:[${abc.toString()}]}]}}`}
            width="50px"
            height="20px"
          />
        </td>
        <td className="right-align">{valueCheckGlobal(value.weight, false, 2)}</td>
        {value.data.map((itme, index) => {
          return <td className="right-align">{valueCheckGlobal(itme.value, false, 2)}</td>;
        })}
      </tr>
    </>
  );
};

export default Inflation;