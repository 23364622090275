import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTheme } from '@material-ui/core/styles';
import ReactApexChart from 'react-apexcharts';
import Download from '../../assets/media/icons/download.svg';
import eye from '../../assets/media/icons/eye.svg';
import Imageicon from '../../assets/media/icons/image.svg';
import color_picker from '../../assets/media/icons/color_picker.svg';
import Barchart from '../../assets/media/icons/bar-chart.svg';
import { ColorPicker } from 'antd';
import Linechart from '../../assets/media/icons/line-chart.svg';
import { formatMoney } from '../../GlobalFunctions';
import ApexCharts from 'apexcharts';
import { modal_close_icon } from '../../api/baseUrl';

const ChartModal = ({
  chartActive,
  setChartActive,
  companyname,
  label,
  chartData,
  slice,
}) => {
  const theme = useTheme();
  let slicevalue = 10;
  if (slice) {
    slicevalue = 100;
  }

  console.log(chartData);

  const value = chartData.slice(-slicevalue).map((d) => d['value']);
  const years = chartData.slice(-slicevalue).map((d) => d['year']);
  const [seriesType, setSeriesType] = useState('bar');
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [dataLabelShow, setDataLabelShow] = useState(true);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const handleOpenColorPicker = (value) => {
    setOpenColorPicker(!value);
  };

  console.log(123, seriesType);

  var labelFormatter = function (value) {
    var val = Math.abs(value);
    if (val >= 1000000) {
      return (val = (val / 1000000).toFixed(1) + ' M');
    } else {
      val = val.toFixed(0);
    }
    return formatMoney(val);
  };

  const ApexOptions = {
    chart: {
      id: 'apexchart-example-view',
      foreColor: theme.palette.primary.main,
      type: 'bar',
      toolbar: {
        tools: {
          download:
            '<img src="' + Download + '" class="ico-download" width="20"> ',
          customIcons: [
            {
              icon: '<img src="' + eye + '" class="ico-download" width="20"> ',
              index: 0,
              title: '',
              class: 'custom-icon',
              click: () => {
                handleDataLable(dataLabelShow);
              },
            },
            {
              icon:
                '<img src="' +
                Imageicon +
                '" class="ico-download" width="20"> ',
              index: 0,
              title: '',
              class: 'custom-icon',
              click: () => {
                downloadSVG('apexchart-example-view');
              },
            },
            {
              icon:
                '<img src="' +
                color_picker +
                '" class="ico-download" width="20"> ',
              index: 0,
              title: '',
              class: 'custom-icon',
              click: () => {
                handleOpenColorPicker(openColorPicker);
                handleChartType(seriesType);
              },
            },
            {
              icon:
                '<img src="' + Barchart + '" class="ico-download" width="20"> ',
              index: 0,
              title: '',
              class: 'custom-icon',

              click: () => {
                handleChartType('bar');
              },
            },
            {
              icon: `<img src="
                ${Linechart} 
                " class="ico-download" width="20"> `,
              index: 0,
              title: '',
              class: 'custom-icon',

              click: () => {
                handleChartType('line');
              },
            },
          ],
        },
        export: {
          svg: {
            filename: label,
          },
          csv: {
            filename: label,
          },
          png: {
            filename: label,
          },
        },
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -10,
      hideOverflowingLabels: true,
      formatter: labelFormatter,
      style: {
        fontSize: '12px',
        fontWeight: 'light',
        posiiton: 'top',
        fontFamily: undefined,
        colors: ['#626768'],
      },
      background: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Number(value).toLocaleString('en-US');
        },
      },
      decimalsInFloat: 2,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Number(value).toLocaleString('en-US');
        },
      },
      decimalsInFloat: 2,
    },

    xaxis: {
      categories: years,
      labels: {
        rotate: -270,
      },
      title: {
        text: label,
        offsetX: 0,
        offsetY: -280,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          posiiton: 'top',
          fontFamily: undefined,
          color: '#000',
        },
      },
    },
    legend: {
      width: 400,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        bottom: 0,
      },
    },
    stroke: {
      width: 0,
      curve: 'straight',
    },
    series: [
      {
        name: '',
        type: seriesType,
        data: value,
      },
    ],
  };

  const handleSeriesColor = (e) => {
    const width = seriesType === 'line' ? 2 : 0;
    const color = e.toHexString();
    const options = {
      fill: {
        colors: [color],
      },
      stroke: {
        colors: [color],
        show: true,
        curve: 'straight',
        width: width,
      },
      dataLabels: {
        enabled: dataLabelShow,
        position: 'top',
      },
    };
    if (color !== false) {
      ApexCharts.exec('apexchart-example-view', 'updateOptions', options);
    }
  };

  const handleChartType = (type) => {
    setSeriesType(type);

    const width = type === 'line' ? 2 : 0;
    setStrokeWidth(width);

    const options = {
      stroke: {
        show: true,
        curve: 'straight',
        width: width,
      },
      dataLabels: {
        enabled: dataLabelShow,
        position: 'top',
      },
    };
    ApexCharts.exec('apexchart-example-view', 'updateOptions', options);
  };

  const handleDataLable = (value) => {
    const width = seriesType === 'line' ? 2 : 0;
    setStrokeWidth(width);

    setDataLabelShow(!value);
    var lebel = {
      dataLabels: {
        enabled: !value,
        position: 'top',
      },
      stroke: {
        show: true,
        curve: 'straight',
        width: width,
      },
    };
    ApexCharts.exec('apexchart-example-view', 'updateOptions', lebel);
  };

  async function downloadSVG(chartId) {
    console.log(window.Apex._chartInstances);
    const chartInstance = window.Apex._chartInstances.find(
      (chart) => chart.id === chartId
    );
    console.log('chartInstance ', chartInstance);
    const base64 = await chartInstance.chart.dataURI();
    console.log('base 64', base64.imgURI);
    const downloadLink = document.createElement('a');
    downloadLink.href = base64.imgURI;
    downloadLink.download = label + '.png';

    // Add the anchor element to the document
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  const handleChartClose = (seriesTypeValue) => {
    // console.log(123, seriesTypeValue);
    var width = seriesTypeValue === 'line' ? 2 : 0;
    setStrokeWidth(width);
    setSeriesType(seriesTypeValue);

    setChartActive(false);
  };

  return (
    <>
      <Modal
        style={styles.modalBackdrop}
        className="table-modal session-modal"
        show={chartActive ? true : false}
        centered
      >
        <Modal.Header style={styles.modalHeader}>
          <Modal.Title
            style={styles.modalTitle}
            className="text-left d-inline-block w-100"
          >
            {companyname}
            <span
              style={styles.close}
              onClick={() => {
                handleChartClose(seriesType);
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: modal_close_icon }} />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalBody}>
          <ColorPicker
            size="small"
            open={openColorPicker}
            onChange={(e) => {
              handleSeriesColor(e);
            }}
            presets={[
              {
                label: 'Recommended',
                colors: [
                  '#002060',
                  '#7F7F7F',
                  '#FADB14',
                  '#8BBB11',
                  '#52C41A',
                  '#13A8A8',
                  '#1677FF',
                  '#2F54EB',
                  '#722ED1',
                  '#EB2F96',
                ],
              },
            ]}
          >
            <div></div>
          </ColorPicker>
          <div>{label} </div>
          <ReactApexChart options={ApexOptions} series={ApexOptions.series} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChartModal;

const styles = {
  modalBackdrop: {
    zIndex: '999999',
    borderRadius: '20px',
  },
  modalHeader: {
    padding: '15px 25px',
    letterSpacing: '0.3px',
    fontWeight: '700',
    color: '#3d3d3d',
    borderBottom: 'var(--light-border)',
  },
  modalTitle: {
    fontWeight: '700',
    color: '#3969D5',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  modalBody: {
    background: '#fff',
    padding: '10px 15px 0px',
  },
  close: {
    cursor: 'pointer',
    float: 'right',
    color: '#3d3d3d',
  },
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  excel: {
    cursor: 'pointer',
    float: 'right',
  },
  sessionAlertContent: {
    position: 'relative',
  },
  para: {
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
  },
  iconSpan: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
    color: '#ffff',
    margin: '0',
  },
  buttonOK: {
    position: 'absolute',
    right: '15px',
    width: '70px',
    border: 'none',
    background: '#3969d5',
    textTransform: 'uppercase',
    color: '#fff',
    fontWeight: '600',
    borderRadius: '7px',
    padding: '4px 10px',
  },
};
