import React, { useState } from 'react';
import SettlementVolume from './settlementVolume';
import KSEChart from './kseChart';
import CompFinancials from './compFinancial';
import StockDataNew from './StockDataNew';
import IndustryAvg from './industryAvg';
import Valuation from './valuationData';
import FinancialCharts from './financials';
import { getLoader } from '../../../GlobalFunctions';
import { useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';

const Overview = (props) => {
  const location = useLocation();
  const [company, setCompany] = useState({});

  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);

  const settlementChartsValue = [
    /* {
      id: 's1',
      value: 'Closing Price',
      color: '0B254A',
      name: 'Closing Price',
    }, */
    {
      id: 's2',
      value: 'Value',
      color: '3969D5',
      name: 'Settlement Ratio',
    },
    {
      id: 's3',
      value: 'total_value',
      color: 'B7CD5E',
      name: 'Traded Value',
    },
  ];

  const settlementChartsVolume = [
    /* {
      id: 's1',
      value: 'Closing Price',
      color: '0B254A',
      name: 'Closing Price',
    }, */
    {
      id: 's2',
      value: 'Vol',
      color: '3969D5',
      name: 'Settlement Ratio',
    },
    {
      id: 's3',
      value: 'total_volume',
      color: 'B7CD5E',
      name: '',
    },
  ];
  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      <div className="dashboard-main container container_box">
        {Object.keys(company).length ? (
          <>
            <Valuation company={company} />
            <div className="overview-main">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-xl-12 d-flex flex-wrap justify-content-between p-0">
                  <div className="col-xl-5 p-0 pr-lg-2">
                    <div className="comp-financial-block">
                      <div className="col-12 p-0">
                        <CompFinancials company={company} />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 p-0 pr-lg-2">
                    <div className="industry-avg-block">
                      <div className="col-12 p-0 data-block">
                        <IndustryAvg company={company} />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 p-0">
                    <div className="comp-financial-block">
                      <div className="col-12 p-0 data-block">
                        <StockDataNew company={company} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 d-flex flex-wrap justify-content-between px-0 pl-xl-2"></div>

                <div className="col-lg-5 p-0"></div>
                <div className="col-xl-12 d-flex flex-wrap justify-content-between px-0 pl-xl-2">
                  {/* <div className="col-lg-5 p-0 research-main pr-lg-2">
                    <PublishReport
                      compnay_id={companyId}
                      compnay_symbol={company.symbol}
                    />
                  </div> */}
                  <div className="col-lg-12 p-0 right">
                    <div className="col-xl-6 p-0 left">
                      <div className="col-xl-12 d-flex flex-wrap justify-content-between px-0 pr-xl-1">
                        <div className="ffl-block col-12 p-0">
                          <div className="title-block block-head-bg-dark">
                            <h4>
                              {company.symbol} Relative Returns against KSE100
                              Index
                            </h4>
                          </div>
                          <div className="col-12 data-block d-flex flex-wrap justify-content-between p-0">
                            <KSEChart company={company} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 p-0 right">
                      <div className="col-xl-12 d-flex flex-wrap justify-content-between px-0 pl-xl-1">
                        <div className="settlement-block col-12 p-0">
                          <div className="title-block block-head-bg-dark">
                            <h4>Settlement Ratio (Volume)</h4>
                          </div>
                          <div className="col-12 data-block d-flex flex-wrap justify-content-between p-0">
                            {settlementChartsVolume.map((s, i) => {
                              return (
                                <SettlementVolume
                                  company={company}
                                  chartId={s.id}
                                  chartValue={s.value}
                                  chartColor={s.color}
                                  legendName={s.name}
                                  key={i}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 p-0"></div>

                {/* <div className="col-lg-5 p-0 research-main pr-lg-2">
                  <TimeLine
                    compnay_id={company.id}
                    compnay_symbol={company.symbol}
                  />
                  <RecentDevelopment
                    compnay_id={company.id}
                    compnay_symbol={company.symbol}
                  />
                </div> */}

                <div className="col-lg-12 p-0">
                  <div className="financial-chart-block d-flex flex-wrap justify-content-between">
                    <FinancialCharts company={company} />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>{getLoader}</>
        )}
      </div>
    </>
  );
};

export default Overview;
