import React, { useState, useEffect } from 'react';
import { valueCheckGlobal } from '../../../GlobalFunctions';
// import axios from 'axios';
import { useCookies } from 'react-cookie';
// import LoadingImage from '../../../assets/media/loader.gif';
import LoadingImage from '../../../assets/media/loader.gif';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
import ChartModal from './../../../layout/modals/ChartModal';
import StockDataTableModal from './../../../layout/modals/StockDataTableModal';

import loader from '../../../assets/media/_loader.gif';
// import { useCookies } from 'react-cookie';

const StockDataNew = ({ company }) => {
  let compId = company.id;
  const [cookies, setCookies, removeCookies] = useCookies();
  const [stockPriceNew, setStockPriceNew] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);

  useEffect(() => {
    setStockPriceNew([]);
    fetch(`${baseUrl}/stockpricedatanew/${compId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStockPriceNew(data);
        setIsLoading2(true);
      });
  }, [compId]);

  const [chartActive, setChartActive] = useState(false);
  const [label, setLable] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [chartData, setchartData] = useState([]);
  const handleChartModal = (company_name, label, chartData) => {
    // console.log(name);
    setCompanyName(company_name);
    setLable(label);
    setchartData(chartData);
    setChartActive(true);
  };

  if (stockPriceNew.length && isLoading2) {
    //console.log(123, stockPriceNew); //getSharePriceData2 generateStockPriceData

    return (
      <>
        <div className="ask-table-format">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th colSpan={3} className="text-left">
                  Stock Data
                </th>
                {stockPriceNew[0].data[0].data.map((item, index) => {
                  return <th className="right-align" key={'year' + index}>{item.year}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {stockPriceNew.map((item, index) => {
                return (
                  <>
                    <tr style={styles.headingtr}>
                      <td colSpan={2}>{item.label}</td>
                    </tr>
                    {item.data.map((item2, index2) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              {item2.label}{' '}
                              <span className="table-unit-col">
                                ({item2.unit})
                              </span>
                            </td>
                            <td colSpan={2}>
                              <span
                                style={styles.chart}
                                onClick={() => {
                                  handleChartModal(
                                    company.name,
                                    item2.label,
                                    item2.data
                                  );
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: modal_chart_icon,
                                  }}
                                />
                              </span>
                            </td>
                            {item2.data.map((item3, index3) => {
                              return (
                                <>
                                  <td key={index3} className="right-align">
                                    {valueCheckGlobal(item3.value)}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        <ChartModal
          chartActive={chartActive}
          setChartActive={setChartActive}
          companyname={companyName}
          label={label}
          chartData={chartData}
        />
        {/* <StockDataTableModal
          tableActive={tableActive}
          setTableActive={setTableActive}
          setChartActive={setChartActive}
          sector={sectorData}
          label={label}
          dataKey={dataKey}
          setCompanyName={setCompanyName}
          setLable={setLable}
          setchartData={setchartData}
        /> */}
      </>
    );
  } else {
    var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    return (
      <div className="ask-table-format">
          <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={7} className="text-left">
                Stock Data
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={styles.headingtr}>
              <td colSpan={2}>Outstanding Shares - Adjusted</td>
            </tr>
            <tr>
              <td colSpan={2}>Total Shares</td>
            </tr>
            <tr>
              <td colSpan={2}>Free Float Shares</td>
            </tr>
            <tr style={styles.headingtr}>
              <td colSpan={2}>Adjusted Stock Prices</td>
            </tr>
            <tr>
              <td colSpan={2}>
                High<span className="table-unit-col">(PKR)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                Low<span className="table-unit-col">(PKR)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                Average<span className="table-unit-col">(PKR)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                Closing<span className="table-unit-col">(PKR)</span>
              </td>
            </tr>
            <tr style={styles.headingtr}>
              <td colSpan={2}>Return Composition</td>
            </tr>
            <tr>
              <td colSpan={2}>
                Capital Gain / (Loss)<span className="table-unit-col">(%)</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Dividend Return</td>
            </tr>
            <tr>
              <td colSpan={2}>
                Total Return<span className="table-unit-col">(%)</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={styles.noContent} className="no-content-load text-center">
          <img style={styles.noContentImage} src={loader} alt="" />
        </div>
      </div>
    );
  }
};

export default StockDataNew;
const styles = {
  noContent: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    transform: 'translateX(calc(0% - 0px))',
    width: '100%',
    paddingLeft: '44%',
    paddingRight: '44%',
    display: 'inline-flex',
    alignItems: 'center',
    // justifyContent: 'center',
    zIndex: '999',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  headingtr: {
    fontWeight: '700',
  },
  position: {
    position: 'relative',
  },
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};
