import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { formatDate, LocalStorageURL } from '../../../GlobalFunctions';
import { Pagination, rem, TextInput } from '@mantine/core';
import LoadingImage from '../../../assets/media/loader.gif';
import { baseUrl, pdf, sorticon } from '../../../api/baseUrl';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import Paginate from 'react-paginate';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar, IconSearch } from "@tabler/icons-react";

const Filing = (props) => {
  const location = useLocation();
  const [company, setCompany] = useState({});
  let compId = location.state != undefined ? location.state.id : 173;
  let symbol = company.symbol != undefined ? company.symbol : 'LUCK';
  const [companyId, setCompanyId] = useState(compId);
  const [count, setCount] = useState(0);
  const [filings, setFilings] = useState([]);
  const [check, setCheck] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPage, setInitialPage] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filings.slice(indexOfFirstPost, indexOfLastPost);
  const [dateValue, setDateValue] = useState();
  const [cookies, setCookies, removeCookies] = useCookies();
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [url, setUrl] = useState(
    `${baseUrl}/filings/${companyId}?page=${currentPage}&postsperpage=${postsPerPage}`
  );

  useEffect(() => {
    setInitialPage(0);
    setCurrentPage(1);
    setUrl(
      `${baseUrl}/filings/${companyId}?page=1&postsperpage=${postsPerPage}`
    );
  }, [companyId, company.id]);

    function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }

  const calendaricon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  useEffect(() => {
    fetch(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFilings(data.data);
        setFilteredData(data.data);
        setCount(data.count);
        setIsLoading(true);
        setCheck(true);
      });
  }, [url]);

  const paginate = (page) => {
    setCurrentPage(page);
    setUrl(`${baseUrl}/filings/${companyId}?page=${page}&postsperpage=${postsPerPage}`);
  };

  const handleDescription = (value) => {
    if (value !== undefined && value !== null) {
      let url2 = url;
      if (value.length) {
        url2 = removeParam("description", url2);
        url2 = url2.replace(`page=${currentPage}`, `page=1`);
        url2 += `&description=${value.toString().toLowerCase()}`;
        setCurrentPage(1);
      }
      setDescriptionValue(value);
      setUrl(url2);
      console.log("New URL: ", url2);
    }
  };
  
  const handleDateValue = (value) => {
    let url2 = url;
    url2 = removeParam("date", url2);
    if (value !== undefined && value[0] !== null && value[1] !== null) {
      const sd_time = new Date(value[0]).getTime() / 1000;
      const ed_time = new Date(value[1]).getTime() / 1000;
  
      if (value.length) {
        url2 = url2.replace(`page=${currentPage}`, `page=1`);
        url2 += `&date=${[sd_time, ed_time].toString()}`;
        setCurrentPage(1);
      }
      setDateValue(value);
      console.log("New URL: ", url2);
    }
    setUrl(url2);
  };
  
    const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

    const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const handlePostsPerPage = (e) => {
    setPostsPerPage(e.target.value);
    setCurrentPage(1);
    setUrl(`${baseUrl}/filings/${companyId}?page=1&postsperpage=${e.target.value}`);
  };

  return (
    <>
      <Header />
      <Search2 companyId={companyId} setCompanyId={setCompanyId} setCompany={setCompany} />
      <Nav companyId={companyId} />
      {isLoading ? (
        <div className="filling_section">
          <div className="container container_box">
              <div className="excel-btn">
                <div className="select_drop">
                  <select
                    className="selectpicker"
                    value={postsPerPage}
                    onChange={handlePostsPerPage}
                  >
                    <option data-tokens="10">10</option>
                    <option data-tokens="25">25</option>
                    <option data-tokens="50">50</option>
                    <option data-tokens="100">100</option>
                  </select>
                </div>
              </div>
              <div className="ask-table-format">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('company_name')} className="company-first-row">
                      <span>
                        <div className="cement-steel-tbl">
                          Company
                          <div
                            className="sorticon"
                            dangerouslySetInnerHTML={{ __html: sorticon }}
                          />
                        </div>
                      </span>
                    </th>
                    <th
                      onClick={() => handleSort('company_symbol')}
                      className="company-second-row"
                    >
                      <span>
                        <div className="cement-steel-tbl justify-center">
                          Symbol
                          <div
                            className="sorticon"
                            dangerouslySetInnerHTML={{ __html: sorticon }}
                          />
                        </div>
                      </span>
                    </th>
                    <th onClick={() => handleSort('date')}>
                      <span>
                        <div className="cement-steel-tbl justify-align">
                          Date
                          <div
                            className="sorticon"
                            dangerouslySetInnerHTML={{ __html: sorticon }}
                          />
                        </div>
                      </span>
                    </th>
                    <th onClick={() => handleSort('description')} className="company-four-row">
                    <span>
                      <div className="cement-steel-tbl">
                        Description
                        <div
                          className="sorticon"
                          dangerouslySetInnerHTML={{ __html: sorticon }}
                        />
                      </div>
                      </span>
                    </th>
                    <th></th>
                  </tr>
                  <tr className="second-table-row">
                    <th></th>
                    <th></th>
                    <th className="report-data-field">
                      <div className="filings-date-field reseach-date-field">
                        <DatePickerInput
                          clearable
                          type="range"
                          valueFormat="DD MMM, YYYY"
                          placeholder="Select Date Range"
                          className="news-date-picker"
                          onChange={handleDateValue}
                          mx="auto"
                          // rightSection={<IconCalendar size="1rem" />}      
                          icon={<IconCalendar size="1.1rem" stroke={1.5} />}              
                        />
                      </div>
                    </th>
                    <th className="filings-input">
                      <TextInput
                        value={descriptionValue}
                        onChange={(event) =>
                          handleDescription(event.currentTarget.value)
                        }
                        searchable
                        placeholder="Search..."
                        rightSection={<IconSearch size="1rem" />}
                        rightSectionWidth={30}
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                      />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length ? (
                    filteredData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.company_name}</td>
                          <td className="center-align">{item.company_symbol}</td>
                          <td className="right-align">{formatDate(item.date)}</td>
                          <td>{item.description.slice(0, 120)}...</td>
                          <td>
                            <Link
                              to={{
                                pathname: `${LocalStorageURL}/filing/${item.pdf}.pdf`,
                              }}
                              target="_blank"
                              download
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: pdf,
                                }}
                              />
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        {check ? 'No Record Found' : 'Loading...'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="dropdown-pagination">
                <Pagination
                  total={Math.ceil(count / postsPerPage)}
                  value={currentPage}
                  onChange={paginate}
                  mt="sm"
                  size="xs"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
    </>
  );
};

export default Filing;