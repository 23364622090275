import React, { useEffect, useState } from 'react';
import Header from '../../../common/header/header';
import { baseUrl } from '../../../api/baseUrl';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { DatePickerInput } from '@mantine/dates';
import { Button, Modal, TextInput } from '@mantine/core';
import LoadingImage from '../../../assets/media/loader.gif';
import Nav from '../../media/nav';
import { IconSearch } from '@tabler/icons-react';

function AnalystBriefing() {
  const location = useLocation();
  let compId = location.state !== undefined ? location.state.id : 189;
  const [currentPrice, setCurrentPrice] = useState({});
  const [result, setResult] = useState([]);
  const [is, setIs] = useState([]);
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});
  const [dateValue, setDateValue] = useState();
  const [visibleItems, setVisibleItems] = useState(10);
  const [opened, setOpened] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const loadMoreItems = () => {
    setVisibleItems((prev) => prev + 4);
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseUrl}/media/analystbriefing`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setResult(data);
        setFilteredData(data);
        setIsLoading(false);
      });
  }, []);

  const handleOpenModal = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setOpened(true);
  };

  //////////////////////////////////////////////////////////////////
  ///////////////////////////////Filter/////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const handleFilterDate = (data) => {
    if (
      dateValue !== undefined &&
      dateValue[0] !== null &&
      dateValue[1] !== null
    ) {
      var sd = dateValue[0];
      var ed = dateValue[1];
      var brand_filter = data.filter((d) => {
        var time = new Date(d.date).getTime();
        return sd <= time && time <= ed;
      });

      return brand_filter;
    }
    return data;
  };

  const handleFilterName = (data) => {
    if (searchInput !== '' && searchInput !== undefined) {
      var brand_filter = data.filter((item) =>
        item.title.toLowerCase().includes(searchInput.toLowerCase())
      );
      return brand_filter;
    }
    return data;
  };

  useEffect(() => {
    let result2 = result;
    if (result2.length && searchInput.length) {
      result2 = handleFilterName(result2);
    }
    console.log(result2.length);
    if (result2.length && dateValue !== undefined) {
      result2 = handleFilterDate(result2);
    }
    setFilteredData(result2);
  }, [dateValue, searchInput]);

  //////////////////////////////////////////////////////////////////
  ///////////////////////////End Filter/////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  return (
    <>
      <Header />
      <div className="result-main analyst">
        <div className="container container_box">
          <Nav companyId={companyId} />
          <div
            className="row resultboxed latest-boxed corporate-briefing-box"
            style={styles.companyname}
          >
            <TextInput
              value={searchInput}
              onChange={(event) => setSearchInput(event.currentTarget.value)}
              searchable
              placeholder="Search..."
              rightSection={<IconSearch size="1rem" />}
              rightSectionWidth={30}
              styles={{ rightSection: { pointerEvents: 'none' } }}
            />
            <DatePickerInput
              type="range"
              placeholder="Pick dates range"
              value={dateValue}
              onChange={setDateValue}
            />
          </div>
          {isLoading ? (
            <div className="main-loader d-flex flex-column justify-content-center align-items-center">
              <img
                src={LoadingImage}
                alt="Loading..."
                style={{ height: '500px' }}
              />
            </div>
          ) : (
            <div className="main_rearch">
              <div>
                <div className="row">
                  <div className="col-md-12 col-xl-12">
                    <div className="res_sec mt-4 corporate-boxes">
                      <div className="row">
                        {filteredData
                          .slice(0, visibleItems)
                          .map((item, index) => (
                            <div
                              className="col-lg-3 col-md-4 col-sm-6"
                              key={index}
                            >
                              <div className="items_box">
                                <img
                                  src={item.thumbnail}
                                  alt=""
                                  className="luckbox1_img thumnail-image"
                                  onClick={() => handleOpenModal(item.url)}
                                  style={{ cursor: 'pointer' }}
                                />
                                <div className="card-body p-0">
                                  <ul className="corporate-underlist">
                                    <li>{item.date2}</li>
                                  </ul>
                                  <h5 className="luckheldtext p-0" onClick={() => handleOpenModal(item.url)}>
                                    {item.title}
                                  </h5>
                                  <p className="fellowparagraph">
                                    {item.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {visibleItems < result.length && (
                        <Button onClick={loadMoreItems} className="mt-3 mb-3 analyst-media-btn">
                          Load More
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal for video playback */}
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="auto"
        centered
      >
        <iframe
          width="560"
          height="315"
          src={currentVideo}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Modal>
    </>
  );
}

const styles = {
  companyname: {
    width: '80%',
    fontSize: '14px',
    margin: 'auto 0',
  },
};

export default AnalystBriefing;