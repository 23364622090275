import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import askanalystlogo from '../../assets/media/images/askanalyst.png';
import alphaanalystlogo from '../../assets/media/images/alphaanalyst.svg';
import UserImg from '../../assets/media/images/profile_user.jpg';
import 'font-awesome/css/font-awesome.min.css';
import { Cookies, useCookies } from 'react-cookie';
import { Redirect, useHistory } from 'react-router-dom';
import { Link, useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import IdleTimer from '../../IdleTimerContainer';
import SessionExpire from '../../layout/login/sessionExpire';
import WhatsappImage from "../../assets/media/images/WhatsApp.svg";

const Navbar = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [userAction, setUserAction] = useState(false);
  const [sessionModal, setSessionModal] = useState(false);
  const [sessionMessage, setSessionMessage] = useState('');

  const [cookies, setCookies, removeCookies] = useCookies([
    'accessToken',
    'user',
  ]);
  const accessToken = cookies.accessToken;
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${cookies?.accessToken}`;

  let logo;
  let financialTab = true;
  if (window.location.host === 'analyst.alphacapital.com.pk') {
    logo = alphaanalystlogo;
    financialTab = false;
  } else if (window.location.host === 'askanalyst.akseerresearch.com') {
    logo = askanalystlogo;
    financialTab = true;
  } else {
    logo = askanalystlogo;
    financialTab = true;
  }

  const userInfo = JSON.parse(localStorage.getItem('userActive'));

  const userInitial = userInfo.name.split('')[0];

  const handleUserAction = () => {
    setUserAction(!userAction);
  };

  const handleSession = () => {
    setSessionModal(true);
  };

  const closeModal = () => {
    setSessionModal(false);
    sessionTimeout();
  };

  const openWhatsApp = () => {
    const phone = '+923463229942';
    const message = 'Hello, I need assistance.';
    const url = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  // start toggle js
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', changeWidth);
  }, []);
  // end toggle js

  function sessionTimeout() {
    setSessionModal(false);
    removeCookies('accessToken');
    removeCookies('user');
    removeCookies('userInformation');
    removeCookies('company');
    localStorage.removeItem('sessionTime');
    history.replace('/');
  }

  const handleLogout = () => {
    removeCookies('user');
    removeCookies('userInformation');
    removeCookies('company');
    removeCookies('accessToken', { path: '/' });
  };

  const handleReset = () => {
    var accessTokenObj = JSON.parse(localStorage.getItem('userActive'));
    history.push(`/reset_password?token=${accessTokenObj.token}`);
  };

  const showMyCookies = () => {
    console.log('showing coookies from header', cookies);
  };

  if (!accessToken) {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  }
  var parameter = location.pathname.split('/');
  return (
    <>
      <IdleTimer
        handleSession={handleSession}
        setSessionMessage={setSessionMessage}
      />
      <div className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Container className="container_box">
            <Row className="justify-content-between w-100 m-0">
              <Col md={2} xs={6} className="p-0">
                <div className="navbar-brand">
                  <img src={logo} alt="logo" className="imglogo" />
                </div>
              </Col>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {(toggleMenu || screenWidth > 1000) && (
                  <ul className="navbar-nav mr-auto header-navbar-nav">
                    <li className="nav-item header-items">
                      <Link
                        className={
                          'nav-link ' +
                          (location.pathname == '/market' ? 'active' : '')
                        }
                        to={{ pathname: `/market` }}
                      >
                        <button
                          type="button"
                          id="controlled-tab-example-tab-technical"
                          role="tab"
                          data-rr-ui-event-key="technical"
                          aria-controls="controlled-tab-example-tabpane-technical"
                          tabIndex="-1"
                        >
                          Market
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item header-items">
                      <Link
                        className={
                          'nav-link ' +
                          (parameter[1] == 'company' ? 'active' : '')
                        }
                        to={{ pathname: `/company/overview` }}
                      >
                        <button
                          type="button"
                          id="controlled-tab-example-tab-technical"
                          role="tab"
                          data-rr-ui-event-key="technical"
                          aria-controls="controlled-tab-example-tabpane-technical"
                          tabIndex="-1"
                        >
                          Companies
                        </button>
                      </Link>
                    </li>

                    <li className="nav-item header-items ">
                      <Link
                        className={
                          'nav-link ' +
                          (location.pathname == '/databank' ? 'active' : '')
                        }
                        to={{ pathname: `/databank` }}
                      >
                        <button
                          type="button"
                          id="controlled-tab-example-tab-technical"
                          role="tab"
                          data-rr-ui-event-key="technical"
                          aria-controls="controlled-tab-example-tabpane-technical"
                          tabIndex="-1"
                        >
                          Data Bank
                        </button>
                      </Link>
                    </li>

                    {financialTab ? (
                      <li className="nav-item header-items ">
                        <Link
                          className={
                            'nav-link ' +
                            (location.pathname == '/financials' ? 'active' : '')
                          }
                          to={{ pathname: `/financials` }}
                          // to="financials"
                        >
                          <button
                            type="button"
                            id="controlled-tab-example-tab-technical"
                            role="tab"
                            data-rr-ui-event-key="technical"
                            aria-controls="controlled-tab-example-tabpane-technical"
                            tabIndex="-1"
                          >
                            Financial Models
                          </button>
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}

                    <li className="nav-item header-items ">
                      <Link
                        className={
                          'nav-link ' +
                          (parameter[1] == 'sector' ? 'active' : '')
                        }
                        to={{ pathname: `/sector/chemical` }}
                      >
                        <button
                          type="button"
                          id="controlled-tab-example-tab-technical"
                          role="tab"
                          data-rr-ui-event-key="technical"
                          aria-controls="controlled-tab-example-tabpane-technical"
                          tabIndex="-1"
                        >
                          Sector Fundamentals
                        </button>
                      </Link>
                    </li>

                    <li className="nav-item header-items ">
                      <Link
                        className={
                          'nav-link ' +
                          (parameter[1] == 'economy' ? 'active' : '')
                        }
                        to={{ pathname: `/economy/currency` }}
                      >
                        <button
                          type="button"
                          id="controlled-tab-example-tab-technical"
                          role="tab"
                          data-rr-ui-event-key="technical"
                          aria-controls="controlled-tab-example-tabpane-technical"
                          tabIndex="-1"
                        >
                          Economy
                        </button>
                      </Link>
                    </li>

                    <li className="nav-item header-items ">
                      <Link
                        className={
                          'nav-link ' +
                          (parameter[1] == 'publisher' ? 'active' : '')
                        }
                        to={{ pathname: `/publisher/investment` }}
                      >
                        <button
                          type="button"
                          id="controlled-tab-example-tab-technical"
                          role="tab"
                          data-rr-ui-event-key="technical"
                          aria-controls="controlled-tab-example-tabpane-technical"
                          tabIndex="-1"
                        >
                          Publisher
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item header-items ">
                      <Link
                        className={
                          'nav-link ' +
                          (parameter[1] == 'media' ? 'active' : '')
                        }
                        to={{ pathname: `/media/analystbriefing` }}
                      >
                        <button
                          type="button"
                          id="controlled-tab-example-tab-technical"
                          role="tab"
                          data-rr-ui-event-key="technical"
                          aria-controls="controlled-tab-example-tabpane-technical"
                          tabIndex="-1"
                        >
                          Media
                        </button>
                      </Link>
                    </li>
                    <div className="whatsapp-plugin" onClick={openWhatsApp}>
                      <img
                        src={WhatsappImage}
                        alt="WhatsApp"
                      />
                    </div>
                  </ul>
                )}
              </div>

              <Col className="d-flex align-items-center justify-content-end pr-0 header-responsive">
                <div className="btn12" onClick={toggleNav}></div>
                <div
                  onMouseEnter={handleUserAction}
                  onMouseLeave={handleUserAction}
                  className={userAction ? 'user-option active' : 'user-option'}
                >
                  <p className="inline-block user-img">
                    {userInfo.name !== '' ? (
                      <span className="userInitials">{userInitial}</span>
                    ) : (
                      <img src={UserImg} alt="user" />
                    )}
                    <span
                      id="user-trigger"
                      className="user-trigger d-inline-block"
                    >
                      <svg
                        width="20px"
                        height="12px"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="angle-down"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        className="svg-inline--fa fa-angle-down fa-w-10"
                      >
                        <path
                          fill="#767676"
                          d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"
                          className=""
                        ></path>
                      </svg>
                    </span>
                  </p>
                  <ul className="list-unstyled m-0">
                    <li className="option-item">
                      <div
                        style={{ marginTop: '10px' }}
                        className="d-flex flex-column"
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: '18px',
                            margin: '0px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {userInfo.name}
                        </p>
                        <p style={{ color: '#00000094' }} className="mb-1 p-0">
                          {userInfo.email}
                        </p>
                      </div>
                    </li>

                    <li className="option-item">
                      <p className="m-0" onClick={handleLogout}>
                        <span className="icon">
                          <svg
                            width="20px"
                            height="20px"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fal"
                            data-icon="sign-out"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="svg-inline--fa fa-sign-out fa-w-16"
                          >
                            <path
                              fill="#767676"
                              d="M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z"
                              className=""
                            ></path>
                          </svg>
                        </span>
                        Logout
                      </p>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </nav>
      </div>

      <SessionExpire
        sessionModal={sessionModal}
        closeModal={closeModal}
        sessionMessage={sessionMessage}
      />
    </>
  );
};

export default Navbar;