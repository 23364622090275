import React, { useState, useEffect } from 'react';
import SectorCharts from './charts/sectorCharts';
import axios from 'axios';
import LoadingImage from '../../../assets/media/loader.gif';
import { baseUrl } from '../../../api/baseUrl';
import Header from '../../../common/header/header';
import Nav from '../nav';

const Chemical = (props) => {
  const [pvcReport, setPvcReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .post(`${baseUrl}/pvcreport`)
      .then(function (response) {
        setPvcReport(response.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log('pvcreport', error);
      });
  }, []);
  return (
    <>
      <Header />
      <div className="investment-main analyst">
        <div className="row investmentboxed" style={styles.navWidth}>
          <Nav nopadding={true} />
        </div>
      </div>
      {isLoading ? (
        <div className="dashboard-main container container_box">
          <div className="ask-table-format">
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th colSpan={2} className="text-left">
                    {'Monthly & Quarterly Data'}
                  </th>
                  <th>&nbsp;</th>
                  <th className="right-align">{pvcReport.header.Q1}</th>
                  <th className="right-align">{pvcReport.header.Q2}</th>
                  <th className="right-align">{pvcReport.header.YoY}</th>
                  <th className="right-align">{pvcReport.header.Q4}</th>
                  <th className="right-align">{pvcReport.header.QoQ}</th>
                  <th className="right-align">{pvcReport.header.currentMonthCurrentYear}</th>
                  <th className="right-align">{pvcReport.header.CurrentMonthpreviousYear}</th>
                  <th className="right-align">{pvcReport.header.YoY}</th>
                  <th className="right-align">{pvcReport.header.previousMonthCurrentYear}</th>
                  <th className="right-align">{pvcReport.header.MoM}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2} className="text-left">
                    <b>Prices</b>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {pvcReport.price.map((value, key) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2} className="text-left">
                          {value.lable}
                        </td>
                        <td className="right-align">{value.unit}</td>
                        {value.price.map((p, key) => {
                          return (
                            <>
                              <td className="right-align">{p.value}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
                <tr>
                  <td colSpan={2} className="text-left">
                    <b>Margin</b>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {pvcReport.margin.map((value, key) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2} className="text-left">
                          {value.lable}
                        </td>
                        <td className="right-align">{value.unit}</td>
                        {value.margin.map((p, key) => {
                          return (
                            <>
                              <td className="right-align">{p.value}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <SectorCharts />
          <div className="ask-table-format">
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th colSpan={2} className="text-left">
                    {'Annual Data'}
                  </th>
                  <th>&nbsp;</th>
                  {pvcReport.header2.map((value, key) => {
                    return (
                      <>
                        <th className="right-align">{value.value}</th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2} className="text-left">
                    <b>Prices</b>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {pvcReport.tenyeardata.map((value, key) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2} className="text-left">
                          {value.lable}
                        </td>
                        <td className="right-align">{value.unit}</td>
                        {value.data.map((p, key) => {
                          return (
                            <>
                              <td className="right-align">{p.value}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
                <tr>
                  <td colSpan={2} className="text-left">
                    <b>Margin</b>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                {pvcReport.tenyearmargin.map((value, key) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2} className="text-left">
                          {value.lable}
                        </td>
                        <td className="right-align">{value.unit}</td>
                        {value.data.map((p, key) => {
                          return (
                            <>
                              <td className="right-align">{p.value}</td>
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-left YoY-color">
                          {'YoY'}
                        </td>
                        <td className="right-align">&nbsp;</td>
                        {value.YoY.map((y, key) => {
                          return (
                            <>
                              <td className="YoY-color right-align">{y.value}</td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
    </>
  );
};
const styles = {
  companylogo: {
    width: '280px',
    float: 'right',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '100%',
    fontSize: '14px',
    justifyContent: 'space-between',
    marginLeft: '0px',
    marginRight: '0px',
  },
};
export default Chemical;