import React, { useEffect, useState } from "react";
import LoadingImage from "../../../assets/media/loader.gif";
import { baseUrl, pdf, sorticon } from "../../../api/baseUrl";
import { useCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";
import Header from "../../../common/header/header";
import Search2 from "../search2";
import Nav from "../nav";
import Paginate from "react-paginate";
import { DatePickerInput } from "@mantine/dates";
import { MultiSelect, Pagination, TextInput, rem } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { IconSearch, IconCalendar } from "@tabler/icons-react";

const ResearchReport = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [valData, setValData] = useState([]);
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [dateValue, setDateValue] = useState();
  const [check, setCheck] = useState(false);
  const [filter, setFilter] = useState([]);
  const [nameValue, setNameValue] = useState();
  const [typeValue, setTypeValue] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [coal, setCoal] = useState([]);
  const [initialPage, setInitialPage] = useState(0);
  const indexOfLastPost = currentPage * postsPerPage;
  const [count, setCount] = useState(0);
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [titleValue, setTitleValue] = useState("");
  const currentPosts = reports.slice(indexOfFirstPost, indexOfLastPost);
  const [url, setUrl] = useState(
    `${baseUrl}/publishreports/${companyId}?page=${currentPage}&postsperpage=${postsPerPage}`
  );

  const paginate = (page) => {
    setCurrentPage(page);
    setUrl(`${baseUrl}/publishreports/${companyId}?page=${page}&postsperpage=${postsPerPage}`);
  };

  const icon = (
    <IconChevronDown style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
  );
  const calendaricon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  useEffect(() => {
    setInitialPage(0);
    setCurrentPage(1);
    setUrl(
      `${baseUrl}/publishreports/${companyId}?page=1&postsperpage=${postsPerPage}`
    );
  }, [companyId, company.id]);

  useEffect(() => {
    fetch(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReports(data.data);
        setFilteredData(data.data);
        setCount(data.count);
        setFilter(data.filter);
        setIsLoading(true);
        console.log(data);
        setCheck(true);
      });
  }, [companyId, url]);

  const handleTypeValue = (value) => {
    var url2 = '';
    url2 = removeParam('type', url);
    if (value.length) {
      url2 = url2 + '&type=' + value.toString();
      setCurrentPage(1);
    }
    setTypeValue(value);
    setUrl(url2);
  };

  const handlePostsPerPage = (e) => {
    setPostsPerPage(e.target.value);
    setCurrentPage(1);
    setUrl(`${baseUrl}/publishreports/${companyId}?page=1&postsperpage=${e.target.value}`);
  };

  function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }

  const handleDateValue = (value) => {
    let url2 = url;
    url2 = removeParam("date", url2);
    if (value !== undefined && value[0] !== null && value[1] !== null) {
      const sd_time = new Date(value[0]).getTime() / 1000;
      const ed_time = new Date(value[1]).getTime() / 1000;
  
      if (value.length) {
        url2 = url2.replace(`page=${currentPage}`, `page=1`);
        url2 += `&date=${[sd_time, ed_time].toString()}`;
        setCurrentPage(1);
      }
      setDateValue(value);
      console.log("New URL: ", url2);
    }
    setUrl(url2);
  };

  const handleTitle = (value) => {
    if (value !== undefined && value !== null) {
      let url2 = url;
      if (value.length) {
        url2 = removeParam("title", url2);
        url2 = url2.replace(`page=${currentPage}`, `page=1`);
        url2 += `&title=${value.toString().toLowerCase()}`;
        setCurrentPage(1);
      }
      setTitleValue(value);
      setUrl(url2);
      console.log("New URL: ", url2);
    }
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {isLoading ? (
        <>
          <div className="dashboard-main container container_box">
              <div className="excel-btn">
                <div className="select_drop">
                  <select
                    className="selectpicker"
                    value={postsPerPage}
                    onChange={handlePostsPerPage}
                  >
                    <option data-tokens="10">10</option>
                    <option data-tokens="25">25</option>
                    <option data-tokens="50">50</option>
                    <option data-tokens="100">100</option>
                  </select>
                </div>
              </div>
              <div className="ask-table-format">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th onClick={() => handleSort("title")}>
                      <div className="cement-steel-tbl">
                        Description
                        <div
                          className="sorticon"
                          dangerouslySetInnerHTML={{ __html: sorticon }}
                        />
                      </div>
                    </th>
                    <th onClick={() => handleSort("created_at")}>
                      <div className="cement-steel-tbl justify-align">
                        Date
                        <div
                          className="sorticon"
                          dangerouslySetInnerHTML={{ __html: sorticon }}
                        />
                      </div>
                    </th>
                    <th onClick={() => handleSort("company_symbol")}>
                      <div className="cement-steel-tbl justify-center">
                        Symbol
                        <div
                          className="sorticon"
                          dangerouslySetInnerHTML={{ __html: sorticon }}
                        />
                      </div>
                    </th>
                    <th onClick={() => handleSort("company_symbol")}>
                      <div className="cement-steel-tbl justify-center">
                        Sector
                        <div
                          className="sorticon"
                          dangerouslySetInnerHTML={{ __html: sorticon }}
                        />
                      </div>
                    </th>
                    <th onClick={() => handleSort("type")}>
                      <div className="cement-steel-tbl justify-center">
                        Type
                        <div
                          className="sorticon"
                          dangerouslySetInnerHTML={{ __html: sorticon }}
                        />
                      </div>
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                  <tr className="second-table-row">
                    <th className="research-text-field">
                      <TextInput
                        value={titleValue}
                        onChange={(event) =>
                          handleTitle(event.currentTarget.value)
                        }
                        searchable
                        placeholder="Search..."
                        rightSection={<IconSearch size="1rem" />}
                        rightSectionWidth={30}
                        styles={{ rightSection: { pointerEvents: "none" } }}
                      />
                    </th>
                    <th className="news-date-sizing steel-data-row">
                      <div className="filings-date-field reseach-date-field">
                        <DatePickerInput
                          clearable
                          type="range"
                          valueFormat="DD MMM, YYYY"
                          placeholder="Select Date Range"
                          className="news-date-picker"
                          onChange={handleDateValue}
                          mx="auto"
                          icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                        />
                      </div>
                    </th>
                    <th></th>
                    <th></th>
                    <th className="news-outlook-flt">
                      <MultiSelect
                        data={filter.type || []}
                        value={typeValue}
                        onChange={handleTypeValue}
                        rightSection={icon}
                        nothingFound="Nothing found"
                        //searchable
                        placeholder="Select Type"
                      />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length ? (
                    filteredData.map((value, index) => {
                      return (
                        <>
                          <tr>
                            <td>{value.title}</td>
                            <td className="right-align">{value.created_at}</td>
                            <td className="center-align">
                              <span className="companytext">
                                {value.company_symbol}
                              </span>
                            </td>
                            <td className="center-align">
                              <span className="cementtext">
                                {value.sector_name}
                              </span>
                            </td>
                            <td className="center-align">
                              <span className="cementtext">{value.type}</span>
                            </td>
                            <td className="center-align">
                              <Link
                                to={{ pathname: `${value.file}` }}
                                target="_blank"
                                download
                              >
                                <div
                                  dangerouslySetInnerHTML={{ __html: pdf }}
                                />
                              </Link>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {check ? "No Record Found" : "Loading..."}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {filteredData.length ? (
              <div className="dropdown-pagination">
                <Pagination
                  total={Math.ceil(count / postsPerPage)}
                  value={currentPage}
                  onChange={paginate}
                  mt="sm"
                  size="xs"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{ width: "100%", height: "100%", color: "#426fd7" }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img src={LoadingImage} style={{ height: "500px" }} />
          </div>
        </>
      )}
    </>
  );
};

const styles = {
  chart: {
    cursor: "pointer",
    float: "left",
    posiiton: "absolute",
    padding: "0px 10px 0 0px",
  },
};

export default ResearchReport;
