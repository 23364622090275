import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import './industryAvg.css';
import loader from '../../../assets/media/_loader.gif';
import { baseUrl } from '../../../api/baseUrl';

const IndustryAvg = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies();

  useEffect(() => {
    setData([]);
    fetch(`${baseUrl}/industrynew/${props.company.id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setIsLoading(true);
      });
  }, [props.company.id]);

  return (
    <div className="ask-table-format" style={styles.position}>
      {isLoading && data.length ? (
        <>
          <table className="table table-responsive overview-first-row">
            <thead>
              <tr>
                <th colSpan={2} className="text-left">
                  Management
                </th>
              </tr>
            </thead>

            <tbody>
              {/* <tr>
                <td>Chairman</td>
                <td>{props.company.Chairman}</td>
              </tr> */}
              <tr>
                <td>CEO</td>
                <td>{props.company.ceo}</td>
              </tr>
              <tr className="sec-row-tbl">
                <td>Sec.</td>
                <td>{props.company.secretary}</td>
              </tr>
            </tbody>
          </table>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th colSpan={2} className="text-left">
                  Industry Averages
                </th>
              </tr>
            </thead>

            <tbody>
              {data.map((d, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {d.label}{' '}
                      <span className="table-unit-col">({d.unit})</span>
                    </td>
                    <td>{valueCheckGlobal(d.value)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        // WHEN DATA IS BEING LOADED ......

        <>
          <table className="table table-responsive mb-0">
            <thead>
              <tr>
                <th colSpan={2} className="text-left">
                  Management
                </th>
              </tr>
            </thead>

            <tbody>
              {/* <tr>
                <td>Chairman</td>
                <td>-</td>
              </tr> */}
              <tr>
                <td>CEO</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Sec.</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th colSpan={2} className="text-left">
                  Industry Averages
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span style={{ color: 'white' }}>.</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={styles.noContent} className="no-content-load text-center">
            <img style={styles.noContentImage} src={loader} alt="" />
          </div>
        </>
      )}
    </div>
  );
};

export default IndustryAvg;

const styles = {
  noContent: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    transform: 'translateX(calc(50% - 90px))',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '999',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
