import React, { useEffect, useState } from 'react';
import { valueCheckGlobal, getLoader } from '../../../GlobalFunctions';
// import axios from 'axios';
// import './research-report.css';
import LoadingImage from '../../../assets/media/loader.gif';
import {
  baseUrl,
  pdf,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
// import Valuation from './../../valuationData';
import ChartModal from '../../../layout/modals/ChartModal';
// import RatioTableModal from '../../../layout/modals/RatioTableModal';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import Paginate from 'react-paginate';

const RecentDevelopment = (props) => {
  const location = useLocation();
  // console.log(location.state);

  let compId = location.state != undefined ? location.state.id : 189;
  // let symbol = 'LUCK';
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});

  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  // setValData(props.allData);
  const [valData, setValData] = useState([]);

  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = reports.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    fetch(`${baseUrl}/timelinesbyid/${companyId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(true);
        setReports(data);
      });
  }, [companyId]);

  const handlePostsPerPage = (e) => {
    setCurrentPage(1);
    setPostsPerPage(e.target.value);
  };

  // if (valData.length && isLoading) {
  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {reports.length && isLoading ? (
        <>
          <div className="dashboard-main container container_box">
            {currentPosts.length > 0 ? (
              <>
                <div className="research-report-main">
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Timeline</th>
                        <th>Symbol</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts.map((value, index) => {
                        return (
                          <tr>
                            <td>{value.timeline}</td>
                            <td>{value.symbol}</td>
                            {/* <td>{value.name}</td> */}
                            <td>
                              <span className="companytext">{value.date}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="dropdown-pagination">
                  <div className="select_drop">
                    <select
                      className="selectpicker"
                      value={postsPerPage}
                      onChange={handlePostsPerPage}
                    >
                      <option data-tokens="25">25</option>
                      <option data-tokens="50">50</option>
                      <option data-tokens="100">100</option>
                      {/* <option data-tokens="All">All</option> */}
                    </select>
                  </div>
                  <Paginate
                    onPageChange={paginate}
                    pageCount={Math.ceil(reports.length / postsPerPage)}
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    containerClassName={'pagination'}
                    pageLinkClassName={'page-number'}
                    previousLinkClassName={'page-number'}
                    nextLinkClassName={'page-number'}
                    activeLinkClassName={'active'}
                  />
                </div>
              </>
            ) : (
              <div className="financial-main d-flex">
                <table class="table table-responsive">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Date</th>
                      <th>Name</th>
                      <th className="text-center">Symbol</th>
                      <th className="text-center">Sector</th>
                      <th className="text-center">Type</th>
                      <th className="text-center">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="13" className="text-center">
                        No Record Found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      ) : (
        <>{getLoader()}</>
      )}
    </>
  );
};

const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};

export default RecentDevelopment;
