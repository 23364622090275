import React, { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { baseUrl } from '../../../api/baseUrl';

const TechTest = (props) => {
  const [destroy, setDestroy] = useState(false);
  const [cookies] = useCookies();
  const chartRef = useRef();
  let companyId = props.companyId;

  const generateChart = () => {
    if (destroy === true) {
      chartRef.current.dispose();
    }
    let root = am5.Root.new('technical-chart');
    chartRef.current = root;

    root.setThemes([am5themes_Animated.new(root)]);

    root.numberFormatter.setAll({
      numberFormat: "#.# ' PKR'",
      numericFields: ['valueY'],
    });

    setDestroy(true);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
        pinchZoomX: true,
      })
    );

    chart.get('colors').set('step', 2);
    chart.root._logo._childrenDisplay.visible = false;
    chart._settings.paddingBottom = 10;
    chart._settings.paddingLeft = 10;

    var valueAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          inside: true,
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        height: am5.percent(70),
      })
    );

    valueAxis.get('renderer').labels.template.setAll({
      centerY: am5.percent(100),
      maxPosition: 0.98,
    });
    valueAxis.get('renderer').grid.template.setAll({
      stroke: 0,
    });

    valueAxis.axisHeader.children.push(
      am5.Label.new(root, {
        text: 'Value',
        fontWeight: 'bold',
        paddingBottom: 5,
        paddingTop: 5,
      })
    );

    var volumeAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          inside: true,
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        height: am5.percent(30),
        layer: 5,
        numberFormat: '#a',
      })
    );

    volumeAxis.get('renderer').labels.template.setAll({
      centerY: am5.percent(100),
      maxPosition: 0.98,
    });
    volumeAxis.get('renderer').grid.template.setAll({
      stroke: 0,
    });

    volumeAxis.axisHeader.set('paddingTop', 10);
    volumeAxis.axisHeader.children.push(
      am5.Label.new(root, {
        text: 'Volume',
        fontWeight: 'bold',
        paddingTop: 5,
        paddingBottom: 5,
      })
    );

    var dateAxis = chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        maxDeviation: 1,
        start: 0,
        end: 0.25,
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    dateAxis.get('renderer').labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
    });
    dateAxis.get('renderer').grid.template.setAll({
      stroke: 0,
    });

    var color = root.interfaceColors.get('background');

    var valueSeries = chart.series.push(
      am5xy.CandlestickSeries.new(root, {
        fill: color,
        clustered: false,
        calculateAggregates: true,
        stroke: color,
        name: 'STCK',
        xAxis: dateAxis,
        yAxis: valueAxis,
        valueYField: 'close',
        openValueYField: 'open',
        lowValueYField: 'low',
        highValueYField: 'high',
        valueXField: 'date',
        lowValueYGrouped: 'low',
        highValueYGrouped: 'high',
        openValueYGrouped: 'open',
        valueYGrouped: 'close',
        legendValueText:
          'open: {openValueY} low: {lowValueY} high: {highValueY} close: {valueY}',
        legendRangeValueText: '{valueYClose}',
      })
    );
    var valueTooltip = valueSeries.set(
      'tooltip',
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        getLabelFillFromSprite: true,
        autoTextColor: false,
        pointerOrientation: 'horizontal',
        labelText:
          "{name}: {valueY} {valueYChangePreviousPercent.formatNumber('[#00ff00]+#,###.##|[#ff0000]#,###.##|[#999999]0')}%",
      })
    );
    valueTooltip
      .get('background')
      .set('fill', root.interfaceColors.get('background'));

    var firstColor = chart.get('colors').getIndex(0);
    var volumeSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'STCK',
        clustered: false,
        fill: firstColor,
        stroke: firstColor,
        valueYField: 'volume',
        valueXField: 'date',
        valueYGrouped: 'sum',
        xAxis: dateAxis,
        yAxis: volumeAxis,
        legendValueText: '{valueY}',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}',
        }),
      })
    );

    var valueLegend = valueAxis.axisHeader.children.push(
      am5.Legend.new(root, {
        useDefaultMarker: true,
      })
    );
    valueLegend.data.setAll([valueSeries]);

    var volumeLegend = volumeAxis.axisHeader.children.push(
      am5.Legend.new(root, {
        useDefaultMarker: true,
      })
    );
    volumeLegend.data.setAll([volumeSeries]);

    chart.leftAxesContainer.set('layout', root.verticalLayout);

    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomX',
        xAxis: dateAxis,
      })
    );
    cursor.lineY.set('visible', false);
    cursor.lineX.set('visible', false);

    var scrollbar = chart.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 10,
      })
    );

    function loadData(unit, min, max, side) {
      min = am5.time.round(new Date(min), unit, 1).getTime();

      let url = `${baseUrl}/technicalchart/day/${min}/${max}/${companyId}`;

      am5.net
        .load(url, chart, {
          requestHeaders: [
            { key: 'Authorization', value: `Bearer ${cookies.accessToken}` },
          ],
        })
        .then(function (result) {
          var data = am5.CSVParser.parse(result.response, {
            delimiter: ',',
            reverse: false,
            skipEmpty: true,
            useColumnNames: true,
          });

          var processor = am5.DataProcessor.new(root, {
            numericFields: ['date', 'open', 'high', 'low', 'close', 'volume'],
          });
          processor.processMany(data);

          var start = dateAxis.get('start');
          var end = dateAxis.get('end');

          var seriesFirst = {};
          var seriesLast = {};

          if (side == 'none') {
            if (data.length > 0) {
              if (dateAxis.get('baseInterval').timeUnit != unit) {
                dateAxis.set('baseInterval', { timeUnit: unit, count: 1 });
              }

              dateAxis.set('min', min);
              dateAxis.set('max', max);
              dateAxis.setPrivate('min', min); // needed in order not to animate
              dateAxis.setPrivate('max', max); // needed in order not to animate

              valueSeries.data.setAll(data);
              volumeSeries.data.setAll(data);
            }
          } else if (side == 'left') {
            // save dates of first items so that duplicates would not be added
            seriesFirst[valueSeries.uid] = valueSeries.data.getIndex(0).date;
            seriesFirst[volumeSeries.uid] = volumeSeries.data.getIndex(0).date;

            for (var i = data.length - 1; i >= 0; i--) {
              var date = data[i].date;
              if (seriesFirst[valueSeries.uid] > date) {
                valueSeries.data.unshift(data[i]);
              }
              if (seriesFirst[volumeSeries.uid] > date) {
                volumeSeries.data.unshift(data[i]);
              }
            }

            min = Math.max(min, absoluteMin);
            dateAxis.set('min', min);
            dateAxis.setPrivate('min', min); // needed in order not to animate
            dateAxis.set('start', 0);
            dateAxis.set('end', (end - start) / (1 - start));
          } else if (side == 'right') {
            seriesLast[valueSeries.uid] = valueSeries.data.getIndex(
              valueSeries.data.length - 1
            ).date;
            seriesLast[volumeSeries.uid] = volumeSeries.data.getIndex(
              volumeSeries.data.length - 1
            ).date;

            for (var i = 0; i < data.length; i++) {
              var date = data[i].date;
              if (seriesLast[valueSeries.uid] < date) {
                valueSeries.data.push(data[i]);
              }
              if (seriesLast[volumeSeries.uid] < date) {
                volumeSeries.data.push(data[i]);
              }
            }
            max = Math.min(max, absoluteMax);
            dateAxis.set('max', max);
            dateAxis.setPrivate('max', max); // needed in order not to animate
            dateAxis.set('start', start / end);
            dateAxis.set('end', 1);
          }
        });
    }

    function loadSomeData() {
      var start = dateAxis.get('start');
      var end = dateAxis.get('end');

      var selectionMin = Math.max(
        dateAxis.getPrivate('selectionMin'),
        absoluteMin
      );
      var selectionMax = Math.min(
        dateAxis.getPrivate('selectionMax'),
        absoluteMax
      );

      var min = dateAxis.getPrivate('min');
      var max = dateAxis.getPrivate('max');

      if (start < 0) {
        loadData(currentUnit, selectionMin, min, 'left');
      }
      if (end > 1) {
        loadData(currentUnit, max, selectionMax, 'right');
      }
    }

    var currentDate = new Date();
    var currentUnit = 'day';

    var min = currentDate.getTime() - am5.time.getDuration('day', 500);
    var max = currentDate.getTime();

    var absoluteMax = max;
    var absoluteMin = new Date(2000, 0, 1, 0, 0, 0, 0);

    chart.events.on('panended', function () {
      loadSomeData();
    });

    var wheelTimeout;
    chart.events.on('wheelended', function () {
      if (wheelTimeout) {
        wheelTimeout.dispose();
      }

      wheelTimeout = chart.setTimeout(function () {
        loadSomeData();
      }, 50);
    });

    // load some initial data
    loadData('day', min, max, 'none');

    chart.appear(1000, 500);
  };

  useEffect(() => {
    generateChart();
  }, [companyId]);

  return (
    <>
      <div id="technical-chart" className="technical-chart"></div>
    </>
  );
};

export default TechTest;