import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { LocalStorageURL } from "../../../GlobalFunctions";
import { MultiSelect, Pagination, rem } from "@mantine/core";
import Paginate from "react-paginate";
import { baseUrl, pdf, sorticon } from "../../../api/baseUrl";
import { Link, useLocation } from "react-router-dom";
import Header from "../../../common/header/header";
import Search2 from "../search2";
import Nav from "../nav";
import { DatePickerInput } from "@mantine/dates";
import LoadingImage from "../../../assets/media/loader.gif";
import { IconCalendar, IconChevronDown } from "@tabler/icons-react";

const Financialstatements = (props) => {
  const location = useLocation();
  const [company, setCompany] = useState({});
  let compId = location.state != undefined ? location.state.id : 173;
  let symbol = company.symbol != undefined ? company.symbol : "LUCK";
  const [companyId, setCompanyId] = useState(compId);
  const [filings, setFilings] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [check, setCheck] = useState(false);
  const [count, setCount] = useState(0);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [dateValue, setDateValue] = useState();
  const [typeValue, setTypeValue] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPage, setInitialPage] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const [coal, setCoal] = useState([]);
  const [nameValue, setNameValue] = useState();
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filings.slice(indexOfFirstPost, indexOfLastPost);
  const [url, setUrl] = useState(
    `${baseUrl}/financialstatement/${companyId}?page=${currentPage}&postsperpage=${postsPerPage}`
  );

  const icon = (
    <IconChevronDown style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
  );

  useEffect(() => {
    setInitialPage(0);
    setCurrentPage(1);
    setUrl(
      `${baseUrl}/financialstatement/${companyId}?page=1&postsperpage=${postsPerPage}`
    );
  }, [companyId, company.id]);

  useEffect(() => {
    fetch(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFilings(data.data);
        setFilteredData(data.data);
        setCount(data.count);
        setFilter(data.filter);
        setIsLoading(true);
        setCheck(true);
        console.log("Authorization: ", `Bearer ${cookies?.accessToken}`);
      });
  }, [url]);

  const handleFilterBrand = (data) => {
    var typeValueLowerCase = typeValue.map((item) => item.toLowerCase());
    if (typeValue.length) {
      var brand_filter = data.filter((item) =>
        typeValueLowerCase.includes(item.type.toLowerCase())
      );
    }
    return brand_filter;
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  useEffect(() => {
    let result = coal;
    if (coal.length && typeValue.length) {
      setUrl(url + "type=" + typeValue.toString());
      result = handleFilterBrand(result);
    }
    if (coal.length && dateValue !== undefined) {
    }
  }, [nameValue]);

  const paginate = (page) => {
    setCurrentPage(page);
    setUrl(`${baseUrl}/financialstatement/${companyId}?page=${page}&postsperpage=${postsPerPage}`);
  };

  const handlePostsPerPage = (e) => {
    setPostsPerPage(e.target.value);
    setCurrentPage(1);
    setUrl(`${baseUrl}/financialstatement/${companyId}?page=1&postsperpage=${e.target.value}`);
  };

  const handleTypeValue = (value) => {
    if (value !== undefined && value !== null) {
      let url2 = url;
  
      url2 = removeParam("type", url2);
  
      if (value.length) {
        
        url2 = url2.replace(`page=${currentPage}`, `page=1`);
    
        url2 += `&type=${value.toString()}`;
        setCurrentPage(1);
      }
  
      setTypeValue(value); 
      setUrl(url2); 
      console.log("New URL: ", url2);
    }
  };

  const calendaricon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  const handleDateValue = (value) => {
    let url2 = url;
    url2 = removeParam("date", url2);
    if (value !== undefined && value[0] !== null && value[1] !== null) {
      const sd_time = new Date(value[0]).getTime() / 1000;
      const ed_time = new Date(value[1]).getTime() / 1000;
  
      if (value.length) {
        url2 = url2.replace(`page=${currentPage}`, `page=1`);
        url2 += `&date=${[sd_time, ed_time].toString()}`;
        setCurrentPage(1);
      }
      setDateValue(value);
      console.log("New URL: ", url2);
    }
    setUrl(url2);
  };

  function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }
  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {isLoading ? (
        <>
          <div className="filling_section">
            <div className="container container_box">
                <div className="excel-btn">
                  <div className="select_drop">
                    <select
                      className="selectpicker"
                      value={postsPerPage}
                      onChange={handlePostsPerPage}
                    >
                      <option data-tokens="10">10</option>
                      <option data-tokens="25">25</option>
                      <option data-tokens="50">50</option>
                      <option data-tokens="100">100</option>
                    </select>
                  </div>
                </div>
                <div className="ask-table-format">
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th onClick={() => handleSort("company_name")}>
                        <span className="filling-filter">
                          <div className="cement-steel-tbl">
                            Company
                            <div
                              className="sorticon"
                              dangerouslySetInnerHTML={{ __html: sorticon }}
                            />
                          </div>
                        </span>
                      </th>
                      <th onClick={() => handleSort("company_symbol")}>
                        <span>
                          <div className="cement-steel-tbl justify-center">
                            Symbol
                            <div
                              className="sorticon"
                              dangerouslySetInnerHTML={{ __html: sorticon }}
                            />
                          </div>
                        </span>
                      </th>
                      <th onClick={() => handleSort("sector_name")}>
                        <span>
                          <div className="cement-steel-tbl justify-center">
                            Sector
                            <div
                              className="sorticon"
                              dangerouslySetInnerHTML={{ __html: sorticon }}
                            />
                          </div>
                        </span>
                      </th>
                      <th onClick={() => handleSort("period")}>
                        <span>
                          <div className="cement-steel-tbl justify-align">
                            Period
                            <div
                              className="sorticon"
                              dangerouslySetInnerHTML={{ __html: sorticon }}
                            />
                          </div>
                        </span>
                      </th>
                      <th onClick={() => handleSort("type")}>
                        <span>
                          <div className="cement-steel-tbl justify-center">
                            Type
                            <div
                              className="sorticon"
                              dangerouslySetInnerHTML={{ __html: sorticon }}
                            />
                          </div>
                        </span>
                      </th>
                      <th></th>
                    </tr>
                    <tr className="second-table-row">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="date-sizing report-data-field">
                        <div className="filings-date-field">
                          <DatePickerInput
                            clearable
                            // maxDate={new Date()}
                            type="range"
                            valueFormat="DD MMM, YYYY"
                            placeholder="Select Date Range"
                            className="news-date-picker"
                            onChange={handleDateValue}
                            mx="auto"
                            icon={<IconCalendar size="1.1rem" stroke={1.5} />} 
                          />
                        </div>
                      </th>
                      <th className="news-outlook-flt">
                        <MultiSelect
                          data={filter.type || []}
                          value={typeValue}
                          onChange={handleTypeValue}
                          rightSection={icon}
                          placeholder="Select Type"
                          searchable
                        />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length ? (
                      filteredData.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td>{item.company_name}</td>
                              <td className="center-align">
                                <span className="luck-blue">
                                  {item.company_symbol}
                                </span>
                              </td>
                              <td className="center-align">
                                <span className="filling-head">
                                  {item.sector_name}
                                </span>
                              </td>
                              <td className="right-align">
                                {item.period}-{item.year}
                              </td>
                              <td className="center-align">
                                <span
                                  className={
                                    item.type == "Annual"
                                      ? "financial-color"
                                      : "type-color"
                                  }
                                >
                                  {item.type == "Annual"
                                    ? "Annual"
                                    : "Quaterly"}
                                </span>
                              </td>
                              <td className="center-align">
                                <Link
                                  to={{
                                    pathname: `${LocalStorageURL}/financialreports/${item.company_symbol}/${item.pdf}.pdf`,
                                  }}
                                  target="_blank"
                                  download
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: pdf,
                                    }}
                                  />
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          {check ? "No Record Found" : "Loading..."}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="dropdown-pagination">
                <Pagination
                  total={Math.ceil(count / postsPerPage)}
                  value={currentPage}
                  onChange={paginate}
                  mt="sm"
                  size="xs"
                />
              </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ width: "100%", height: "100%", color: "#426fd7" }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img src={LoadingImage} style={{ height: "500px" }} />
          </div>
        </>
      )}
    </>
  );
};

export default Financialstatements;
