import React, { useState, useEffect } from 'react';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import { Link, useLocation } from 'react-router-dom';
import Paginate from 'react-paginate';
import { baseUrl, sorticon } from '../../../api/baseUrl';
import LoadingImage from "../../../assets/media/loader.gif";
import { formatDate } from '../../../GlobalFunctions';
import { DatePickerInput } from '@mantine/dates';
import { MultiSelect, Pagination, TextInput, rem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { IconSearch, IconCalendar } from '@tabler/icons-react';

function Index() {  
  const location = useLocation();
  const [company, setCompany] = useState({});
  let compId = location.state != undefined ? location.state.id : 189;
  let symbol = company.symbol;
  const [companyId, setCompanyId] = useState(compId);
  const [dateValue, setDateValue] = useState();
  const [initialPage, setInitialPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [check, setCheck] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [titleValue, setTitleValue] = useState('');
  const [filter, setFilter] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const [typeValue, setTypeValue] = useState([]);
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = news.slice(indexOfFirstPost, indexOfLastPost);
  const [url, setUrl] = useState(`${baseUrl}/news/${compId}?page=${currentPage}&postsperpage=${postsPerPage}`);

  useEffect(() => {
      fetch(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => response.json())
        .then((data) => {
            setNews(data.data);
            setFilteredData(data.data);
            setCheck(true);
            setCount(data.count);
            setFilter(data.filter);
            console.log(data);
            setIsLoading(true);
        });
  }, [compId, url]);

  useEffect(() => {
    setInitialPage(0);
    setCurrentPage(1);
    setUrl(
      `${baseUrl}/news/${companyId}?page=1&postsperpage=${postsPerPage}`
    );
  }, [companyId, company.id]);

  const paginate = (page) => {
    setCurrentPage(page);
    setUrl(`${baseUrl}/news/${companyId}?page=${page}&postsperpage=${postsPerPage}`);
  };

  const handlePostsPerPage = (e) => {
    setPostsPerPage(e.target.value);
    setCurrentPage(1);
    setUrl(`${baseUrl}/news/${companyId}?page=1&postsperpage=${e.target.value}`);
  };

  const icon = (
    <IconChevronDown style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
  );

  const calendaricon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  const handleTypeValue = (value) => {
    var url2 = '';
    url2 = removeParam('type', url);
    if (value.length) {
      url2 = url2 + '&type=' + value.toString();
      setCurrentPage(1);
    }
    setTypeValue(value);
    setUrl(url2);
  };

  const handleDateValue = (value) => {
    let url2 = url;
    url2 = removeParam("date", url2);
    if (value !== undefined && value[0] !== null && value[1] !== null) {
      const sd_time = new Date(value[0]).getTime() / 1000;
      const ed_time = new Date(value[1]).getTime() / 1000;
  
      if (value.length) {
        url2 = url2.replace(`page=${currentPage}`, `page=1`);
        url2 += `&date=${[sd_time, ed_time].toString()}`;
        setCurrentPage(1);
      }
      setDateValue(value);
      console.log("New URL: ", url2);
    }
    setUrl(url2);
  };

  function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }

  const handleTitle = (value) => {
    if (value !== undefined && value !== null) {
      let url2 = url;
      if (value.length) {
        url2 = removeParam("title", url2);
        url2 = url2.replace(`page=${currentPage}`, `page=1`);
        url2 += `&title=${value.toString().toLowerCase()}`;
        setCurrentPage(1);
      }
      setTitleValue(value);
      setUrl(url2);
      console.log("New URL: ", url2);
    }
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {isLoading ? (
         <>
      <div className="main_news">
        <div className="container container_box">
          <div className="excel-btn">
          <div className="select_drop">
                <select
                  className="selectpicker"
                  value={postsPerPage}
                  onChange={handlePostsPerPage}
                >
                  <option data-tokens="10">10</option>
                  <option data-tokens="25">25</option>
                  <option data-tokens="50">50</option>
                  <option data-tokens="100">100</option>
                </select>
              </div>
              </div>
              <div className="ask-table-format">
              <table className="table table-responsive">
              <thead>
                <tr>
                  <th onClick={() => handleSort('title')}>
                  <div className="cement-steel-tbl">
                    Title
                    <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('date')}>
                    <div className="cement-steel-tbl justify-align">
                      Date
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('description')}>
                  <div className="cement-steel-tbl">
                    Detail
                    <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                          </div>
                    </th>
                    <th onClick={() => handleSort('sector')}>
                    <div className="cement-steel-tbl justify-center">
                    Company/Sector
                    <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                          </div>
                    </th>
                  <th onClick={() => handleSort('type')}>
                    <div className="cement-steel-tbl justify-center">
                      Outlook
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                          </div>
                  </th>
                </tr>
                <tr className="second-table-row">
                  <th className="research-text-field">
                  <TextInput
                    value={titleValue}
                    onChange={(event) => handleTitle(event.currentTarget.value)}
                    searchable
                    placeholder="Search..."
                    rightSection={<IconSearch size="1rem" />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                  />
                  </th>
                  <th className="news-date-sizing">
                    <div className="filings-date-field">
                      <DatePickerInput
                        clearable
                        // maxDate={new Date()}
                        type="range"
                        valueFormat="DD MMM, YYYY"
                        placeholder="Select Date Range"
                        onChange={handleDateValue}
                        className="news-date-picker"
                        mx="auto"
                        icon={<IconCalendar size="1.1rem" stroke={1.5} />} 
                      />
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                  <th className="news-outlook-flt">
                  <MultiSelect
                      data={filter.type || []}
                      value={typeValue}
                      onChange={handleTypeValue}
                      rightSection={icon}
                      placeholder="Select Outlook"
                      searchable
                      nothingFound="Nothing found"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length ? (
                  filteredData.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <Link
                            to={{ pathname: `${item.link}` }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.title}
                          </Link>
                        </td>
                        <td className="right-align">{formatDate(item.date)}</td>
                        <td>
                            {item.description.slice(0, 47)}...
                        </td>
                        <td className="center-align">
                          <span className="sector-economy">{item.sector}</span>
                        </td>
                        <td className="center-align">
                          <span
                            className={
                              item.type == 'Positive'
                                ? 'positive-green'
                                : item.type == 'Negative'
                                ? 'negative-red'
                                : 'neutral-grey'
                            }
                          >
                            {item.type}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      {check ? 'No Record Found' : 'Loading...'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {filteredData.length ? (
            <div className="dropdown-pagination">
                <Pagination
                  total={Math.ceil(count / postsPerPage)}
                  value={currentPage}
                  onChange={paginate}
                  mt="sm"
                  size="xs"
                />
               </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      </>
      ) : (
        <>
          <div
            style={{ width: "100%", height: "100%", color: "#426fd7" }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img src={LoadingImage} style={{ height: "500px" }} />
          </div>
        </>
      )}
    </>
  );
};

export default Index;
