import React, { useEffect, useState } from 'react';
import { valueCheckGlobal, getLoader } from '../../../GlobalFunctions';
// import axios from 'axios';
import LoadingImage from '../../../assets/media/loader.gif';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
  modal_excel_icon,
} from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
// import Valuation from './../../valuationData';
import ChartModal from './../../../layout/modals/ChartModal';
import RatioTableModal from './../../../layout/modals/RatioTableModal';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ComparisonChartModal from '../../../layout/modals/ComparisonChartModal';
import * as XLSX from 'xlsx';

const RatioTable = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});

  // let sectorId = props.company.sector_id;
  // console.log('i am here1', props.company);
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  // setValData(props.allData);
  const [valData, setValData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  useEffect(() => {
    fetch(`${baseUrl}/rationew/${companyId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setValData(data);
        setIsLoading(true);
        // console.log(data, 'ffls1234 response');
        // generateChart(data);
      });
  }, [companyId]);

  const [tableActive, setTableActive] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [label, setLabel] = useState('');
  const [ratioName, setRatioName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [chartData, setchartData] = useState([]);

  const handleChartModal = (company_name, label, chartData) => {
    // console.log(name);
    setCompanyName(company_name);
    setLabel(label);
    setchartData(chartData);
    setChartActive(true);
  };

  const excel_name = company.name + ` - Ratios`;

  const handleExport = () => {
    const table = document.getElementById('table');
    const wb = XLSX.utils.table_to_book(table, { sheet: 'file' });
    XLSX.writeFile(wb, `${excel_name}.xlsx`);
  };

  // if (valData.length && isLoading) {
  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {valData.length && isLoading ? (
        <>
          <div className="dashboard-main container container_box">
            <div className="cash-table-data">
              <span style={styles.excel}>
                <button
                  onClick={handleExport}
                  filename={excel_name}
                  className="custom-export-button download-table-xls-button income-excel-btn"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: modal_excel_icon,
                    }}
                  />
                </button>
              </span>
            </div>
            <div className="ask-table-format ask-top-space">
              <table className="table table-responsive" id="table">
                <thead>
                  <tr>
                    <th colSpan={3}>Ratios</th>
                    <th>&nbsp;</th>
                    {valData
                      ? valData[0].data[0].data.slice(-10).map((year, i) => {
                          return i !== 10 ? <th key={i} className="right-align">{year.year}</th> : null;
                        })
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {valData.map((section, ii) => {
                    return (
                      <>
                        <tr className="bg-td">
                          <td colSpan={14} className="bold">
                            {section.section}
                          </td>
                        </tr>
                        {section.data.map((r, i) => {
                          var abc = r.data.map(function (el) {
                            return el.value;
                          });
                          return (
                            <>
                              <tr key={i}>
                                <td colSpan={2}>
                                  {r.label}{' '}
                                  <span className="table-unit-col">
                                    ({r.unit})
                                  </span>
                                </td>
                                <td>
                                  <span
                                    style={styles.chart}
                                    onClick={() => {
                                      handleChartModal(
                                        company.name,
                                        r.label + ' - ' + r.unit,
                                        r.data
                                      );
                                    }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: modal_chart_icon,
                                      }}
                                    />
                                  </span>
                                  <span style={styles.chart}>
                                    <Link
                                      // target="_blank"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        window.open(
                                          e.currentTarget.href,
                                          '_blank',
                                          'location = yes,height = 450,width = 950,scrollbars = yes,status = yes,left = 200),top = 100'
                                        );
                                      }}
                                      to={{
                                        pathname: `/comparison/ratios/annual/${company.symbol}/${r.name}`,
                                      }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: modal_table_icon,
                                        }}
                                      />
                                    </Link>
                                  </span>
                                </td>
                                <td className="right-align">
                                  <img
                                    src={`https://quickchart.io/chart?bkg=transparent&c={type:%27sparkline%27,data:{datasets:[{backgroundColor:%20%27rgba(24,183,21,%200.1)%27,fill:true,borderWidth:8,borderColor:%27rgb(24,183,21)%27,data:[${abc.toString()}]}]}}`}
                                    width="50px"
                                    height="20px"
                                  />
                                </td>
                                {r.data.slice(-10).map((d, i) => {
                                  return i !== 10 ? (
                                    <td key={i} className="right-align">{valueCheckGlobal(d.value)}</td>
                                  ) : null;
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {chartActive && (
              <ComparisonChartModal
                chartActive={chartActive}
                setChartActive={setChartActive}
                companyname={companyName}
                label={label}
                chartData={chartData}
                slice={false}
              />
            )}
          </div>
        </>
      ) : (
        <>{getLoader()}</>
      )}
    </>
  );
};

const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};

export default RatioTable;
