import React, { useEffect, useState } from 'react';
import axios from 'axios';
import arrowicon from '../../../../src/assets/media/images/arrow-icon.svg';
import arrowdown from '../../../../src/assets/media/images/arrow-down.svg';
import { baseUrl } from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import Loader from '../../../assets/media/_loader.gif';
import StockChartModal from './StockChartModal';

// import incomeStatement from './tabs/income';
const ValuationData = ({ company }) => {
  //console.log('yes', company);
  // console.log(`${baseUrl}/stockchart/${company.symbol}`);
  const [valuationData, setValuationData] = useState(null);
  const [stockChart, setStockChart] = useState([]);
  const [stockChartActive, setStockChartActive] = useState('1D');
  const [cookies, setCookies, removeCookies] = useCookies();
  //const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValuationData(null);
    //setIsLoading(false);
    console.log(valuationData);
    fetch(`${baseUrl}/sharepricedatanew/${company.id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setValuationData(data);
        //setIsLoading(true);
        // console.log(data);
      });
  }, [company.id]);

  useEffect(() => {
    setStockChart(null);
    fetch(`${baseUrl}/stockchartnew/${company.id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStockChart(data);
      });
  }, [company.id]);

  // const data = 'have a good day';
  return (
    <>
      <div className="valuation-box main-box">
        <div className="col-12 p-0 data-block d-flex flex-wrap justify-content-between">
          {valuationData ? (
            <>
              <div className="col-lg-6 px-0">
                <div className="main-box main-title-dashboad d-flex">
                  <div className="title-block flex-wrap align-items-center">
                    <h1>
                      {company.name}{' '}
                      <span className="symbol">({company.symbol})</span>
                      <span className="sector">{company.sector}</span>
                    </h1>
                    <div className="title-block">
                      <p className="d-flex flex-wrap justify-content-between">
                        <span className="Asof">
                          {/* Prices as of{' '} */}
                          {valuationData && valuationData.date
                            ? valuationData.date
                            : ''}
                        </span>
                      </p>
                    </div>

                    {valuationData && valuationData.current ? (
                      <>
                        {valuationData.direction == '+' ? (
                          <div>
                            <span className="numsymbol">Current Price</span>

                            <p className="pkrnum">
                              PKR {valuationData.current}&nbsp;
                              <img src={arrowicon} alt="" />
                              <span className="countsymbol text-green">
                                {valuationData.change}(
                                {valuationData.change_in_percentage} %)
                              </span>
                            </p>
                          </div>
                        ) : (
                          <div>
                            <span className="numsymbol">Current Price</span>

                            <p className="pkrnum">
                              PKR {valuationData.current}
                              &nbsp;
                              <img src={arrowdown} />
                              <span className="countsymbol text-red">
                                {valuationData.change} (
                                {valuationData.change_in_percentage} %)
                              </span>
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="chart__tools">
                  <div className="chart__range">
                    <div
                      className={
                        stockChartActive == '1D'
                          ? 'chart__range__item chart__range__item--selected'
                          : 'chart__range__item'
                      }
                      data-name="1D"
                      onClick={() => {
                        setStockChartActive('1D');
                      }}
                    >
                      1D
                    </div>
                    {stockChart
                      ? stockChart.map((value, index) => {
                          return (
                            <div
                              className={
                                stockChartActive == value.lable
                                  ? 'chart__range__item chart__range__item--selected'
                                  : 'chart__range__item'
                              }
                              data-name="{value.lable}"
                              onClick={() => {
                                setStockChartActive(value.lable);
                              }}
                            >
                              {value.lable}
                            </div>
                          );
                        })
                      : ''}
                  </div>
                  {/* <div className="quote__date">
                    As of <span id="current-date">Mon, Jun 26, 2023</span>{' '}
                    <span id="current-time">3:05 PM</span>
                  </div> */}
                </div>
                {valuationData && valuationData.current_feed ? (
                  <div className="col-lg-12 pl-0">
                    {stockChartActive == '1D' && (
                      <StockChartModal
                        current_feed={valuationData.current_feed}
                        chart_id="1D"
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}

                {stockChart
                  ? stockChart.map((value, index) => {
                      return (
                        <div className="col-lg-12 p-0 pr-xl-0">
                          {stockChartActive == value.lable && (
                            <StockChartModal
                              current_feed={value.data}
                              chart_id={value.lable}
                            />
                          )}
                        </div>
                      );
                    })
                  : ''}
              </div>
              <div className="col-lg-6 px-0  pl-xl-0 side-figures">
                <ul className="list-unstyled m-0">
                  <li className="lb w-100 li-heading border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">Trading Data </span>
                    </p>
                  </li>
                  <li className="lb1 rb tb w-25 ">
                    <p className="d-flex flex-wrap">
                      <span className="label">Open</span>
                      <span className="data">
                        {valuationData.open
                          ? `${valueCheckGlobal(valuationData.open)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb tb w-25">
                    <p className="d-flex flex-wrap">
                      <span className="label">High</span>
                      <span className="data">
                        {valuationData.high
                          ? `${valueCheckGlobal(valuationData.high)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb tb w-25">
                    <p className="d-flex flex-wrap">
                      <span className="label">Low</span>
                      <span className="data">
                        {valuationData.low
                          ? `${valueCheckGlobal(valuationData.low)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="rb tb w-25">
                    <p className="d-flex flex-wrap">
                      <span className="label">LDCP</span>
                      <span className="data">
                        {valuationData.ldcp
                          ? `${valueCheckGlobal(valuationData.ldcp)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="lb1 tb w-25">
                    <p className="d-flex flex-wrap">
                      <span className="label">Bid Price</span>
                      <span className="data">
                        {valuationData.bid_price
                          ? `${valueCheckGlobal(valuationData.bid_price)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="rb tb w-25">
                    <p className="d-flex flex-wrap">
                      <span className="label">Bid Volume</span>
                      <span className="data">
                        {valuationData.bid_volume
                          ? `${valueCheckGlobal(valuationData.bid_volume)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="rb tb w-25">
                    <p className="d-flex flex-wrap">
                      <span className="label">Ask Price</span>
                      <span className="data">
                        {valuationData.ask_price
                          ? `${valueCheckGlobal(valuationData.ask_price)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="rb rb tb w-25">
                    <p className="d-flex flex-wrap">
                      <span className="label">Ask Volume</span>
                      <span className="data">
                        {valuationData.ask_volume
                          ? `${valueCheckGlobal(valuationData.ask_volume)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="lb1 tb w-50">
                    <p className="d-flex flex-wrap">
                      <span className="label">Volume</span>
                      <span className="data">
                        {valuationData.volume
                          ? `${valueCheckGlobal(valuationData.volume)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="rb tb w-50">
                    <p className="d-flex flex-wrap">
                      <span className="label">Value (PKRmn)</span>
                      <span className="data">
                        {valuationData.value
                          ? `${valueCheckGlobal(valuationData.value)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="lb1 tb w-50">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Circuit Breaker{' '}
                        {/* <span className="table-unit-col">( (PKRbn))</span> */}
                      </span>
                      <span className="data">
                        {valuationData.circuit_breaker
                          ? `${valueCheckGlobal(
                              valuationData.circuit_breaker.lower_lock
                            )} - ${valueCheckGlobal(
                              valuationData.circuit_breaker.upper_lock
                            )}`
                          : null}
                      </span>
                    </p>
                  </li>

                  <li className="lb tb w-50">
                    <p className="d-flex flex-wrap">
                      <span className="label">Day Range</span>
                      <span className="data">
                        {valuationData.day_range
                          ? `${valueCheckGlobal(
                              valuationData.day_range.low
                            )} - ${valueCheckGlobal(
                              valuationData.day_range.high
                            )}`
                          : null}
                      </span>
                    </p>
                  </li>

                  <li className="w-100 li-heading border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">Returns & History </span>
                    </p>
                  </li>
                  <li className="lb1 rb tb cw-3 border-right-0 return-txt">
                    <p className="d-flex flex-wrap">
                      <span className="label">52-Week High</span>
                      <span className="data">
                        {valuationData.fifty_two_week_high
                          ? `${valueCheckGlobal(
                              valuationData.fifty_two_week_high
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 tb cw-3 return-txt border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">1M Return (%)</span>
                      <span className="data">
                        {valuationData.total_return['1M']
                          ? `${valueCheckGlobal(
                              valuationData.total_return['1M']
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="lb1 rb tb cw-3 return-txt">
                    <p className="d-flex flex-wrap">
                      <span className="label">1Y Return (%)</span>
                      <span className="data">
                        {valuationData.total_return['1Y']
                          ? `${valueCheckGlobal(
                              valuationData.total_return['1Y']
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="lb1 bb rb tb cw-3 return-txt border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">52-Week Low</span>
                      <span className="data">
                        {valuationData.fifty_two_week_low
                          ? `${valueCheckGlobal(
                              valuationData.fifty_two_week_low
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 bb tb cw-3 border-right-0 return-txt">
                    <p className="d-flex flex-wrap">
                      <span className="label">3M Return (%)</span>
                      <span className="data">
                        {valuationData.total_return['3M']
                          ? `${valueCheckGlobal(
                              valuationData.total_return['3M']
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 bb tb cw-3 return-txt">
                    <p className="d-flex flex-wrap">
                      <span className="label">3Y Return (%)</span>
                      <span className="data">
                        {valuationData.total_return['3Y']
                          ? `${valueCheckGlobal(
                              valuationData.total_return['3Y']
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="lb1 bb rb tb cw-3 border-right-0 return-txt">
                    <p className="d-flex flex-wrap">
                      <span className="label">52-Week Average</span>
                      <span className="data">
                        {valuationData.fifty_two_week_average
                          ? `${valueCheckGlobal(
                              valuationData.fifty_two_week_average
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 bb tb cw-3 return-txt border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">6M Return (%)</span>
                      <span className="data">
                        {valuationData.total_return['6M']
                          ? `${valueCheckGlobal(
                              valuationData.total_return['6M']
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 bb tb cw-3 return-txt">
                    <p className="d-flex flex-wrap">
                      <span className="label">5Y Return (%)</span>
                      <span className="data">
                        {valuationData.total_return['5Y']
                          ? `${valueCheckGlobal(
                              valuationData.total_return['5Y']
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="tb w-100 li-heading border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">Valuation </span>
                    </p>
                  </li>

                  <li className="lb1 rb tb cw-3 border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">PE</span>
                      <span className="data">
                        {valuationData.pe
                          ? `${valueCheckGlobal(valuationData.pe)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 tb cw-3">
                    <p className="d-flex flex-wrap">
                      <span className="label">Div Yield</span>
                      <span className="data">
                        {valuationData.dividend_yield
                          ? `${valueCheckGlobal(valuationData.dividend_yield)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="lb1 rb tb cw-3 border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">PBV</span>
                      <span className="data">
                        {valuationData.pbv
                          ? `${valueCheckGlobal(valuationData.pbv)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="lb1 bb rb tb cw-3">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Enterprise Value{' '}
                        <span className="table-unit-col">(PKR-mn)</span>{' '}
                      </span>{' '}
                      <span className="data">
                        {valuationData.ev
                          ? `${valueCheckGlobal(valuationData.ev)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 bb tb cw-3 border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Total Debt{' '}
                        <span className="table-unit-col">(PKR-mn)</span>
                      </span>{' '}
                      <span className="data">
                        {valuationData.total_debt
                          ? `${valueCheckGlobal(valuationData.total_debt)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 bb tb cw-3">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Cash <span className="table-unit-col">(PKR-mn)</span>
                      </span>{' '}
                      <span className="data">
                        {valuationData.cash
                          ? `${valueCheckGlobal(valuationData.cash)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="tb w-100 li-heading border-right-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">Equity Profile </span>
                    </p>
                  </li>

                  <li className="lb1 rb tb w-50">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Market Cap
                        <span className="table-unit-col"> (PKR-mn)</span>{' '}
                      </span>
                      <span className="data">
                        {valuationData.market_cap
                          ? `${valueCheckGlobal(valuationData.market_cap)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 bb rb tb w-50 border-left-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Shares<span className="table-unit-col"> (PKR-mn)</span>{' '}
                      </span>
                      <span className="data">
                        {valuationData.dividend_yield
                          ? `${valueCheckGlobal(valuationData.shares)}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                  <li className="rb1 tb w-50">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Free Float
                        <span className="table-unit-col"> (PKR-mn)</span>{' '}
                      </span>
                      <span className="data">
                        {valuationData.pbv
                          ? `${valueCheckGlobal(valuationData.free_float)}`
                          : '0'}
                      </span>
                    </p>
                  </li>

                  <li className="lb1 bb rb tb w-50 border-left-0">
                    <p className="d-flex flex-wrap">
                      <span className="label">
                        Free Float
                        <span className="table-unit-col"> (%)</span>{' '}
                      </span>{' '}
                      <span className="data">
                        {valuationData.ev
                          ? `${valueCheckGlobal(
                              valuationData.free_float_percentage
                            )}`
                          : '0'}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>{' '}
            </>
          ) : (
            // : "Loading . . . ."}
            <div
              style={styles.noContent}
              className="no-content-load text-center"
            >
              <img style={styles.noContentImage} src={Loader} alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ValuationData;

const styles = {
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};

// export { Currentprice };
