import React, { useEffect, useState } from 'react';
import arrowdown from '../../../src/assets/media/images/arrow-down.svg';
import arrowicon from '../../../src/assets/media/images/arrow-icon.svg';
import Header from '../../common/header/header';
import axios from 'axios';
import { pdf, baseUrl } from '../../api/baseUrl';
import { calendaricon, downloadicon } from '../../api/imageUrl';
import { Link, useLocation } from 'react-router-dom';
import { Tabs } from '@mantine/core';
import { useCookies } from 'react-cookie';
import loader from '../../../src/assets/media/_loader.gif';
import { formatDate, addDigitWithSign, addDigit } from '../../GlobalFunctions';
import StockChartModal from './StockChartModal';
import ApexCharts from 'apexcharts';

const Market = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [indices, setIndices] = useState([]);
  const [reports, setReports] = useState([]);
  const [news, setNews] = useState([]);
  const [marketroundup, setMarketRoundUp] = useState([]);
  const [morningbriefing, setMorningBriefing] = useState([]);
  const [morningbriefingreports, setMorningBriefingReports] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoadingMb, setIsLoadingMb] = useState(false);
  const [valuationData, setValuationData] = useState(null);
  const [stockChart, setStockChart] = useState([]);
  const [stockChartActive, setStockChartActive] = useState('1D');
  const [activeTab, setActiveTab] = useState();
  const [series, setSeries] = useState('KSE100');

  useEffect(() => {
    axios
      .get(`${baseUrl}/morningbriefingchart`)
      .then(function (response) {
        setMorningBriefing(response.data);
        setIsLoadingMb(true);
      })
      .catch(function (error) {
        console.log('morningbriefingchart====>', error);
      });
  }, []);

  function numberWithCommas(x) {
    if (Number(x)) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return x;
    }
  }

  useEffect(() => {
    fetch(`${baseUrl}/stocks`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStocks(data);
        // setIsLoading(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/indices`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIndices(data);
        // setIsLoading(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/reports/3`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReports(data);
        // setIsLoading(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/news/all?page=1&postsperpage=3`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setNews(data.data);
        //setIsLoading(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/marketroundup`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMarketRoundUp(data);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/morningbriefing`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMorningBriefingReports(data);
      });
  }, []);

  const stocks_array = [
    {
      key: 'top_active_stocks',
      heading: 'Top Active Stocks',
    },
    {
      key: 'top_advancers',
      heading: 'Top Advancers',
    },
    {
      key: 'top_decliners',
      heading: 'Top Decliners',
    },
  ];

  const handleActiveTab = (value) => {
    setSeries(value);
    setStockChartActive('1D');

    ApexCharts.exec(
      'apexchart-example',
      'updateSeries',
      [
        {
          data: indices[value].feed['1D'],
        },
      ],
      true
    );
  };

  const handleFrequency = (value) => {
    setStockChartActive(value);

    ApexCharts.exec(
      'apexchart-example',
      'updateSeries',
      [
        {
          data: indices[series].feed[value],
        },
      ],
      true
    );
  };

  var frequencies = [
    { id: '1D', value: '1D' },
    { id: '1M', value: '1M' },
    { id: '3M', value: '3M' },
    { id: '6M', value: '6M' },
    { id: '1Y', value: '1Y' },
    { id: '3Y', value: '3Y' },
    { id: '5Y', value: '5Y' },
  ];
  var color = "['#6287a2', '#e9ecf4']";

  return (
    <>
      <Header />
      <div className="container container_box">
        <div className="landing">
          <div className="container container_box">
            <div className="row">
              <div className="col-md-12 pl-0">
                {Object.keys(indices).length ? (
                  <>
                    <div className="Tab_learn">
                      <h2>Indices</h2>
                      <div className="border_line"></div>
                      <div className="tab-card">
                        <div className="tab-card-header">
                          <div className="row">
                            <div className="col-md-6">
                              <Tabs
                                defaultValue="KSE100"
                                // value={activeTab}
                                // onChange={setActiveTab}
                              >
                                <Tabs.List>
                                  {Object.keys(indices).map((item, index) => {
                                    return (
                                      <Tabs.Tab
                                        value={item}
                                        onClick={() => handleActiveTab(item)}
                                      >
                                        {item}

                                        {indices[item].change < 0 ? (
                                          <img
                                            src={`https://quickchart.io/chart?bkg=transparent&c={type:'sparkline',data:{datasets:[{backgroundColor:'rgba(201,69,69, 1)',fill:false, borderWidth:20,borderColor:'rgb(129, 202, 38, 1)',data:[${indices[item].sparkline}]}]}}`}
                                            width="29px"
                                            height="11px"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src={`https://quickchart.io/chart?bkg=transparent&c={type:'sparkline',data:{datasets:[{backgroundColor: 'rgba(24,183,21, 1)',fill:false,borderWidth:20,borderColor:'rgb(129, 202, 38, 1)',data:[${indices[item].sparkline}]}]}}`}
                                            width="29px"
                                            height="11px"
                                            alt=""
                                          />
                                        )}

                                        {/* <img
                                            src={`https://quickchart.io/chart?bkg=transparent&c={type:'sparkline',data:{datasets:[{backgroundColor: 'rgba(129, 202, 38, 1)',fill:false,borderWidth:20,borderColor:'rgb(129, 202, 38, 1)',data:[${indices[item].sparkline}]}]}}`}
                                            // src={chartline}
                                            width="29px"
                                            height="11px"
                                            alt=""
                                          /> */}
                                      </Tabs.Tab>
                                    );
                                  })}
                                </Tabs.List>

                                {Object.keys(indices).map((item, index) => {
                                  return (
                                    <Tabs.Panel value={item}>
                                      <div className="tab-content">
                                        <div
                                          className="tab-pane fade show active p-3"
                                          id={item}
                                          role="tabpanel"
                                          aria-labelledby={item + '-tab'}
                                        >
                                          <div className="chart-row-one mb-0">
                                            <div className="indices order-md-1">
                                              <div className="row indices-border-bottom">
                                                <div className="col-4 text-left m-auto">
                                                  <p className="heading number">
                                                    {addDigit(
                                                      indices[item].index,
                                                      2
                                                    )}
                                                  </p>
                                                  <p className="content mb-0">
                                                    {addDigit(
                                                      indices[item].volume,
                                                      0
                                                    )}
                                                  </p>
                                                </div>

                                                <div className="col-4 text-left pr-0 spark-chart">
                                                  {indices[item].change < 0 ? (
                                                    <img
                                                      src={`https://quickchart.io/chart?bkg=transparent&c={type:'sparkline',data:{datasets:[{backgroundColor:'rgba(201,69,69, 0.1)',fill:false, borderWidth:8,borderColor:'rgb(201,69,69)',data:[${indices[item].sparkline}]}]}}`}
                                                    />
                                                  ) : (
                                                    <img
                                                      src={`https://quickchart.io/chart?bkg=transparent&c={type:'sparkline',data:{datasets:[{backgroundColor: 'rgba(24,183,21, 0.1)',fill:true,borderWidth:8,borderColor:'rgb(24,183,21)',data:[${indices[item].sparkline}]}]}}`}
                                                    />
                                                  )}
                                                </div>
                                                <div className="col-4 text-right pr-0 m-auto">
                                                  <p
                                                    className={
                                                      indices[item].change < 0
                                                        ? 'heading text-color-red number'
                                                        : 'heading text-color-green number'
                                                    }
                                                  >
                                                    {addDigit(
                                                      indices[item]
                                                        .change_in_percent,
                                                      2
                                                    )}
                                                    %
                                                  </p>
                                                  <p
                                                    className={
                                                      indices[item].change < 0
                                                        ? 'content text-color-red mb-0'
                                                        : 'content text-color-green mb-0'
                                                    }
                                                  >
                                                    {addDigit(
                                                      indices[item].change,
                                                      2
                                                    )}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="row pt-3">
                                                <div className="col-4 text-left">
                                                  <p className="heading">
                                                    HIGH
                                                  </p>
                                                  <p className="content">
                                                    {addDigit(
                                                      indices[item].high,
                                                      2
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="col-4 pl-3">
                                                  <p className="heading text-start">
                                                    LOW
                                                  </p>
                                                  <p className="content text-start">
                                                    {addDigit(
                                                      indices[item].low,
                                                      2
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="col-4 pr-0 incides-text">
                                                  <p className="heading">
                                                    VOLUME
                                                  </p>
                                                  <p className="content">
                                                    {addDigit(
                                                      indices[item].volume,
                                                      0
                                                    )}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-4 text-left">
                                                  <p className="heading">
                                                    52 WEEK HIGH
                                                  </p>
                                                  <p className="content">
                                                    {addDigit(
                                                      indices[item].max,
                                                      2
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="col-4 pl-3">
                                                  <p className="heading text-start">
                                                    52 WEEK LOW
                                                  </p>
                                                  <p className="content text-start">
                                                    {addDigit(
                                                      indices[item].min,
                                                      2
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="col-4 pr-0 incides-text">
                                                  <p className="heading">
                                                    PREV. CLOSE
                                                  </p>
                                                  <p className="content">
                                                    {addDigit(
                                                      indices[item].ldcp,
                                                      2
                                                    )}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-4 text-left">
                                                  <p className="heading">
                                                    P/E Ratio
                                                  </p>
                                                  <p className="content">
                                                    #N/A
                                                  </p>
                                                </div>
                                                <div className="col-4 pl-3 pr-0">
                                                  <p className="heading text-start">
                                                    DIVIDEND YIELD
                                                  </p>
                                                  <p className="content text-start">
                                                    #N/A
                                                  </p>
                                                </div>
                                                <div className="col-4 pr-0 incides-text">
                                                  <p className="heading">
                                                    MARKET CAP
                                                  </p>
                                                  <p className="content">
                                                    #N/A
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Tabs.Panel>
                                  );
                                })}
                              </Tabs>
                            </div>
                            <div className="col-lg-6">
                              <div className="indices-chart order-md-12 pr-0">
                                <div className="chart__tools">
                                  <div className="chart__range">
                                    {frequencies.map((value, index) => {
                                      return (
                                        <div
                                          className={
                                            stockChartActive == value.value
                                              ? 'chart_rangeitem chartrange_item--selected'
                                              : 'chart_rangeitem'
                                          }
                                          data-name={value.value}
                                          onClick={() => {
                                            handleFrequency(value.value);
                                          }}
                                        >
                                          {value.value}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              {Object.keys(indices).length ? (
                                <div className="col-lg-12 p-0 pr-xl-0">
                                  <StockChartModal
                                    current_feed={indices}
                                    chart_id={stockChartActive}
                                    series={series}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="border_line"></div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={styles.noContent}
                      className="no-content-load text-center"
                    >
                      <img style={styles.noContentImage} src={loader} />
                    </div>
                  </>
                )}
                {Object.keys(stocks).length ? (
                  <div className="top_active">
                    <div className="row">
                      {stocks_array.map((value, index) => {
                        return (
                          <div className="col-md-4">
                            <div className="top_advance">
                              <h2>{value.heading}</h2>
                              <div className="ask-table-format market-tbl-fmt">
                                <table className="table side-table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Symbol</th>
                                      <th className="right-align" scope="col">
                                        Price
                                      </th>
                                      <th className="right-align" scope="col">
                                        &nbsp;
                                      </th>
                                      <th className="right-align" scope="col">
                                        Change
                                      </th>
                                      <th className="right-align" scope="col">
                                        Volume
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {stocks[value.key].map((value2, index2) => {
                                      return (
                                        <tr>
                                          <td>{value2.Symbol}</td>
                                          <td className="right-align">
                                            {Number(value2.LastPrice).toFixed(
                                              2
                                            )}
                                          </td>
                                          {value2.Dir == '+' ? (
                                            <>
                                              <td className="right-align">
                                                <img src={arrowicon} alt="" />
                                              </td>
                                              <td
                                                id="arrow-up"
                                                className="right-align"
                                              >
                                                {Number(value2.Change).toFixed(
                                                  2
                                                )}{' '}
                                                ({value2.percentage.toFixed(1)}
                                                %)
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td className="right-align">
                                                <img src={arrowdown} alt="" />
                                              </td>
                                              <td
                                                id="arrow-down"
                                                className="right-align"
                                              >
                                                {Number(value2.Change).toFixed(
                                                  2
                                                )}{' '}
                                                ({value2.percentage.toFixed(1)}
                                                %)
                                              </td>
                                            </>
                                          )}
                                          <td className="right-align">
                                            {numberWithCommas(
                                              value2.TotalVolume
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      style={styles.noContent}
                      className="no-content-load text-center"
                    >
                      <img style={styles.noContentImage} src={loader} />
                    </div>
                  </>
                )}
                <div className="research-briefing">
                  <div className="row chart-row-two">
                    <div className="col-md-8">
                      <div className="charting">
                        <div className="row">
                          <div className="col-md-6 float-left research">
                            <div className="research_view">
                              <h2 className="mt-0">Research Reports</h2>
                              {/* <Link to="/market/reportlisting">View all</Link> */}
                            </div>
                            <div className="border_line"></div>
                            {reports.length ? (
                              reports.map((value, index) => {
                                if (value.company_symbol == null) {
                                  var logo = value.sector_name;
                                } else {
                                  var logo = value.company_symbol;
                                }
                                return (
                                  <>
                                    <div className="researchitem">
                                      <div className="main_row">
                                        <div className="learn_icon">
                                          <img
                                            src={`https://admin.akseerresearch.com/logo36/${logo}.svg`}
                                            alt={logo}
                                          />
                                        </div>
                                        <div className="heading">
                                          {value.title.slice(0, 100)}
                                          {value.title.length > 80 ? '...' : ''}
                                        </div>
                                      </div>
                                      <div className="col-md-12 content">
                                        {value.content.slice(0, 100)}
                                        {value.content.length > 80 ? '...' : ''}
                                      </div>
                                      <div className="col-md-12 calander d-flex align-items-center">
                                        <div
                                          className="d-flex"
                                          dangerouslySetInnerHTML={{
                                            __html: calendaricon,
                                          }}
                                        />
                                        {formatDate(value.created_at)}
                                        <span className="latest_more">
                                          <Link
                                            to={{ pathname: `${value.file}` }}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <span>Download </span>
                                            <span
                                              className=""
                                              dangerouslySetInnerHTML={{
                                                __html: downloadicon,
                                              }}
                                            ></span>
                                          </Link>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="border_line"></div>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <div
                                  style={styles.noContent}
                                  className="no-content-load text-center"
                                >
                                  <img
                                    style={styles.noContentImage}
                                    src={loader}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div className="col-md-6 float-right latest">
                            <h2 className="mt-0">Latest News</h2>
                            <div className="border_line"></div>
                            {news.length ? (
                              news.map((value, index) => {
                                return (
                                  <>
                                    <div className="researchitem">
                                      <div className="main_row">
                                        <div className="learn_icon">
                                          <img
                                            src={`https://admin.akseerresearch.com/logo36/${value.sector}.svg`}
                                            alt={value.sector}
                                          />
                                          {/* <img src={gasicon} alt="" /> */}
                                        </div>
                                        <div className="heading">
                                          {value.title.slice(0, 100)}
                                          {value.title.length > 80 ? '...' : ''}
                                        </div>
                                      </div>
                                      <div className="col-md-12 content">
                                        {value.description.slice(0, 100)}
                                        {value.description.length > 80
                                          ? '...'
                                          : ''}
                                      </div>
                                      <div className="col-md-12 calander d-flex align-items-center">
                                        <div
                                          className="d-flex"
                                          dangerouslySetInnerHTML={{
                                            __html: calendaricon,
                                          }}
                                        />
                                        {formatDate(value.date)}
                                        <span className="float-right ml-auto">
                                          <Link
                                            to={{ pathname: `${value.link}` }}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            View More
                                          </Link>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="border_line"></div>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <div
                                  style={styles.noContent}
                                  className="no-content-load text-center"
                                >
                                  <img
                                    style={styles.noContentImage}
                                    src={loader}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="morning_section">
                        <div className="briefing-context">
                          <h1>Morning Briefing</h1>
                        </div>
                        {morningbriefingreports.length ? (
                          <>
                            <div className="more_detail">
                              {morningbriefingreports
                                .slice(0, 6)
                                .map((value, index) => {
                                  return (
                                    <div className="briefing-pdf">
                                      <span>
                                        Morning Briefing -{' '}
                                        {formatDate(value.date)}
                                      </span>
                                      <span>
                                        <Link
                                          to={{ pathname: `${value.file}` }}
                                          target="_blank"
                                          download
                                        >
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: pdf,
                                            }}
                                          />
                                        </Link>
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={styles.noContent}
                              className="no-content-load text-center"
                            >
                              <img style={styles.noContentImage} src={loader} />
                            </div>
                          </>
                        )}
                      </div>

                      <div className="morning_section market_round">
                        <div className="briefing-context">
                          <h1>Market Roundup</h1>
                        </div>
                        {marketroundup.length ? (
                          <>
                            <div className="more_detail">
                              {marketroundup.slice(0, 6).map((value, index) => {
                                return (
                                  <div className="briefing-pdf">
                                    <span>
                                      Market Roundup - {formatDate(value.date)}
                                    </span>
                                    <span>
                                      <Link
                                        to={{ pathname: `${value.file}` }}
                                        target="_blank"
                                        download
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: pdf,
                                          }}
                                        />
                                      </Link>
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={styles.noContent}
                              className="no-content-load text-center"
                            >
                              <img style={styles.noContentImage} src={loader} />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="yesterday_context briefing-context">
                  <h1>
                    Yesterday Closing -{' '}
                    {Object.keys(morningbriefing).length
                      ? formatDate(morningbriefing.date)
                      : null}
                  </h1>
                </div>

                <div className="market_briefing">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="briefing-data">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="lipi-position">
                              <div className="net_boxed">
                                <h1>Net LIPI/FIPI Position</h1>
                              </div>

                              {Object.keys(morningbriefing).length > 0 ? (
                                <div className="ask-table-format">
                                  <table className="table side-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th className="right-align" scope="col">
                                          USD Mn
                                        </th>
                                        <th className="right-align" scope="col">
                                          Volume
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {morningbriefing.net !== undefined &&
                                        morningbriefing.net.map(
                                          (value, index) => {
                                            return (
                                              <tr>
                                                <td>{value.label}</td>
                                                <td
                                                  className={`${
                                                    value.value >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.value,
                                                    2
                                                  )}
                                                </td>
                                                <td
                                                  className={`${
                                                    value.cy >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.cy,
                                                    2
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={styles.noContent}
                                    className="no-content-load text-center"
                                  >
                                    <img
                                      style={styles.noContentImage}
                                      src={loader}
                                    />
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="lipi-position">
                              <div className="net_boxed sector_sizing">
                                <h1>FIPI Sector-Wise</h1>
                              </div>
                              {Object.keys(morningbriefing).length ? (
                                <div className="ask-table-format">
                                  <table className="table side-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th className="right-align" scope="col">
                                          USD Mn
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {morningbriefing.sector !== undefined &&
                                        morningbriefing.sector.map(
                                          (value, index) => {
                                            return (
                                              <tr>
                                                <td>{value.label}</td>
                                                <td
                                                  className={`${
                                                    value.value >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.value,
                                                    2
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={styles.noContent}
                                    className="no-content-load text-center"
                                  >
                                    <img
                                      style={styles.noContentImage}
                                      src={loader}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="lipi-position">
                              <div className="net_boxed sector_sizing">
                                <h1>Currencies</h1>
                              </div>
                              {Object.keys(morningbriefing).length ? (
                                <div className="ask-table-format">
                                  <table className="table side-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th className="right-align" scope="col">
                                          Last Close
                                        </th>
                                        <th className="right-align" scope="col">
                                          Change
                                        </th>
                                        <th className="right-align" scope="col">
                                          CYTD %
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {morningbriefing.currency !== undefined &&
                                        morningbriefing.currency.map(
                                          (value, index) => {
                                            return (
                                              <tr>
                                                <td>{value.label}</td>
                                                <td
                                                  className={`${
                                                    value.value >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.value,
                                                    3
                                                  )}
                                                </td>
                                                <td
                                                  className={`${
                                                    value.change >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.change,
                                                    2
                                                  )}
                                                  %
                                                </td>
                                                <td
                                                  className={`${
                                                    value.cytd >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.cytd,
                                                    2
                                                  )}
                                                  %
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={styles.noContent}
                                    className="no-content-load text-center"
                                  >
                                    <img
                                      style={styles.noContentImage}
                                      src={loader}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="lipi-position">
                              <div className="net_boxed">
                                <h1>Major Indices</h1>
                              </div>
                              {Object.keys(morningbriefing).length ? (
                                <div className="ask-table-format">
                                  <table className="table side-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th className="right-align" scope="col">
                                          Value
                                        </th>
                                        <th className="right-align" scope="col">
                                          Change
                                        </th>
                                        <th className="right-align" scope="col">
                                          FYTD
                                        </th>
                                        <th className="right-align" scope="col">
                                          CYTD
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {morningbriefing.indices !== undefined &&
                                        morningbriefing.indices.map(
                                          (value, index) => {
                                            return (
                                              <tr>
                                                <td>{value.label}</td>
                                                <td
                                                  className={`${
                                                    value.value >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.value
                                                  )}
                                                </td>
                                                <td
                                                  className={`${
                                                    value.change >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.change,
                                                    1
                                                  )}
                                                  %
                                                </td>
                                                <td
                                                  className={`${
                                                    value.fytd >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.fytd,
                                                    0
                                                  )}
                                                  %
                                                </td>
                                                <td
                                                  className={`${
                                                    value.cytd >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.cytd,
                                                    0
                                                  )}
                                                  %
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={styles.noContent}
                                    className="no-content-load text-center"
                                  >
                                    <img
                                      style={styles.noContentImage}
                                      src={loader}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="lipi-position">
                              <div className="net_boxed sector_sizing">
                                <h1>Commodities</h1>
                              </div>
                              {Object.keys(morningbriefing).length ? (
                                <div className="ask-table-format">
                                  <table className="table side-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th className="right-align" scope="col">
                                          Unit
                                        </th>
                                        <th className="right-align" scope="col">
                                          Price
                                        </th>
                                        <th className="right-align" scope="col">
                                          Change
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {morningbriefing.commodities !==
                                        undefined &&
                                        morningbriefing.commodities.map(
                                          (value, index) => {
                                            return (
                                              <tr>
                                                <td>{value.label}</td>
                                                <td className="right-align">
                                                  {value.unit}
                                                </td>
                                                <td
                                                  className={`${
                                                    value.value >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.value,
                                                    2
                                                  )}
                                                </td>
                                                <td
                                                  className={`${
                                                    value.change >= 0
                                                      ? 'green-text'
                                                      : 'red-text'
                                                  } right-align center-text padding-5`}
                                                >
                                                  {addDigitWithSign(
                                                    value.change,
                                                    2
                                                  )}
                                                  %
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={styles.noContent}
                                    className="no-content-load text-center"
                                  >
                                    <img
                                      style={styles.noContentImage}
                                      src={loader}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '240px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '100%',
    fontSize: '14px',
    justifyContent: 'space-between',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
export default Market;
