import React, { useEffect, useState } from 'react';
import { valueCheckGlobalForFinancials } from '../../../GlobalFunctions';
import { useCookies } from 'react-cookie';
import LoadingImage from '../../../assets/media/loader.gif';
import { SegmentedControl } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
  modal_excel_icon,
} from '../../../api/baseUrl';
import ComparisonChartModal from '../../../layout/modals/ComparisonChartModal';
import * as XLSX from 'xlsx';

const IncomeTable = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});
  const [incomeStatement, setIncomeStatement] = useState([]);
  const [is, setIs] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [sectorData, setSectorData] = useState([]);
  const [value, setValue] = useState('annual');

  useEffect(() => {
    fetch(`${baseUrl}/is/${companyId}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIs(data);
        setIncomeStatement(data[value]);
        setIsLoading(true);
      });
  }, [companyId]);

  let boldHeadings = [
    'Gross profit',
    'Operating profit',
    'EBITDA',
    'EPS - Diluted',
    'DPS',
    'Profit before tax',
    'Profit after tax',
  ];
  const headingCheck = (heading) => {
    return boldHeadings.includes(heading);
  };

  const [tableActive, setTableActive] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [label, setLable] = useState('');
  const [analysis, setAnalysis] = useState('off');
  const [chartData, setchartData] = useState([]);
  const [headingId, setHeadingId] = useState();
  const [descriptionId, setDescriptionId] = useState();
  const [slice, setSlice] = useState(-11);
  const [colspan, setColspan] = useState(14);

  const handleChartModal = (company_name, label, chartData) => {
    // console.log(incomeStatement);
    setCompanyName(company_name);
    setLable(label + ' - PKR(mn)');
    setchartData(chartData);
    setChartActive(true);
  };

  const handleTableModal = (name, lable, headingId, descriptionId) => {
    setHeadingId(headingId);
    setDescriptionId(descriptionId);
    setCompanyName(name);
    setLable(lable);
    setTableActive(true);
  };

  const handleSwitch = (value) => {
    console.log(is[value]);
    setIncomeStatement(is[value]);
    setValue(value);
    setAnalysis('off');
  };

  const excel_name =
    company.name +
    ` - (${
      value.charAt(0).toUpperCase() + value.slice(1)
    }) Income Statement - PKR (mn)`;

  const handleExport = () => {
    const table = document.getElementById('table');
    const wb = XLSX.utils.table_to_book(table, { sheet: 'file' });
    XLSX.writeFile(wb, `${excel_name}.xlsx`);
  };

  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {incomeStatement.length && isLoading ? (
        <div className="dashboard-main container container_box">
          <div className="table-data-format">
            <div className="row" style={(styles.companyname, styles.lables)}>
              <div className="col-sm" style={styles.companylogoimage}>
                <div style={styles.lables}>
                  <SegmentedControl
                    value={value}
                    onChange={handleSwitch}
                    data={[
                      { label: 'Annual', value: 'annual' },
                      { label: 'Quarterly', value: 'quarter' },
                    ]}
                  />
                </div>
              </div>
            </div>
            <span style={styles.excel}>
              <button
                onClick={handleExport}
                filename={excel_name}
                className="custom-export-button download-table-xls-button income-excel-btn"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: modal_excel_icon,
                  }}
                />
              </button>
            </span>
          </div>
          <div className="ask-table-format ask-top-space">
            <table
              id="table"
              className="table table-responsive"
              style={{ width: '100%' }}
            >
              <thead>
                <tr>
                  <th colSpan={2}>Income Statement - PKR (mn)</th>
                  <th export={false}></th>
                  <th export={false}></th>
                  {incomeStatement[0].data.slice(-11).map((y, i) =>
                    i !== 11 ? (
                      <th key={i} className="right-align">
                        {y.year}
                      </th>
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {incomeStatement.map((r, i) => {
                  var abc = r.data.map(function (el) {
                    return el.value;
                  });
                  return (
                    <tr key={i} className={r.bold == 1 ? 'bold' : ''}>
                      <td colSpan={2}>{r.label}</td>
                      <td>
                        <span
                          style={styles.chart}
                          onClick={() => {
                            handleChartModal(company.name, r.label, r.data);
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: modal_chart_icon,
                            }}
                          />
                        </span>

                        <span style={styles.chart}>
                          <Link
                            target="_blank"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                e.currentTarget.href,
                                '_blank',
                                'location = yes,height = 450,width = 950,scrollbars = yes,status = yes,left = 200),top = 100'
                              );
                            }}
                            to={{
                              pathname: `/comparison/incomestatement/${value}/${company.symbol}/${r.description_id}`,
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: modal_table_icon,
                              }}
                            />
                          </Link>
                        </span>
                      </td>
                      <td>
                        <img
                          src={`https://quickchart.io/chart?bkg=transparent&c={type:%27sparkline%27,data:{datasets:[{backgroundColor:%20%27rgba(24,183,21,%200.1)%27,fill:true,borderWidth:8,borderColor:%27rgb(24,183,21)%27,data:[${abc.toString()}]}]}}`}
                          width="50px"
                          height="20px"
                        />
                      </td>

                      {r.data.slice(-11).map((d, j) => {
                        return j !== 11 ? (
                          <td key={j} className="right-align">
                            {valueCheckGlobalForFinancials(d.value)}
                          </td>
                        ) : null;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}

      {chartActive && (
        <ComparisonChartModal
          chartActive={chartActive}
          setChartActive={setChartActive}
          companyname={companyName}
          label={label}
          chartData={chartData}
          slice={false}
        />
      )}
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },

  lables: {
    float: 'right',
    textAlign: 'right',
  },
  companylogo: {
    width: '200px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px 0px',
  },
  companyname: {
    width: '75%',
    fontSize: '14px',
    margin: 'auto',
  },
};
export default IncomeTable;
