import React, { useState, useEffect } from 'react';
import { valueCheckGlobal } from '../../../GlobalFunctions';
// import axios from 'axios';
import { useCookies } from 'react-cookie';
// import LoadingImage from '../../../assets/media/loader.gif';
import LoadingImage from '../../../assets/media/loader.gif';
import ChartModal from './../../../layout/modals/ChartModal';
import CompanyFinancialTableModal from './../../../layout/modals/CompanyFinancialTableModal';
import ValuationTableModal from './../../../layout/modals/ValuationTableModal';

import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
} from '../../../api/baseUrl';
import loader from '../../../assets/media/_loader.gif';

const CompFinancials = ({ company }) => {
  let compId = company.id;
  let sectorId = company.sector_id;

  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [companyFinancials, setCompanyFinancials] = useState([]);

  const [chartActive, setChartActive] = useState(false);
  const [lable, setLable] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [chartData, setchartData] = useState([]);

  useEffect(() => {
    setCompanyFinancials([]);
    
    fetch(`${baseUrl}/companyfinancialnew/${compId}?companyfinancial=true`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCompanyFinancials(data);
        setIsLoading(true);
      });
  }, [compId]);

  const handleChartModal = (company_name, lable, chartData) => {
    // console.log(name);
    setCompanyName(company_name);
    setLable(lable);
    setchartData(chartData);
    setChartActive(true);
  };

  /*   const handleTableModal = (lable, name) => {
    setCompanyName(name);
    setLable(lable);
    setTableActive(true);
  }; */

  if (companyFinancials.length && isLoading) {
    return (
      <>
        <div className="ask-table-format">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th colSpan={2} className="text-left">
                  Company Financials
                </th>
                <th></th>
                {companyFinancials[0].data.slice(-6).map((item, index) => {
                  return <th className="right-align" key={'year' + index}>{item.year}</th>;
                })}
                {/* <th>TTM</th> */}
              </tr>
            </thead>
            {companyFinancials ? (
              <tbody>
                {companyFinancials.map((d, index) => {
                  return (
                    <tr key={index}>
                      <td colSpan={2}>
                        {d.label}{' '}
                        <span className="table-unit-col">({d.unit})</span>
                      </td>
                      <td>
                        <span
                          style={styles.chart}
                          onClick={() => {
                            handleChartModal(company.name, d.label, d.data);
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: modal_chart_icon,
                            }}
                          />
                        </span>
                      </td>
                      {d.data.slice(-6).map((v, i) => (
                        <td key={i} className="right-align">
                          {v.value ? valueCheckGlobal(v.value) : '0'}
                        </td>
                      ))}
                      {/* <td>N/A</td> */}
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              ''
            )}
          </table>
        </div>
        <ChartModal
          chartActive={chartActive}
          setChartActive={setChartActive}
          companyname={companyName}
          lable={lable}
          chartData={chartData}
        />
        {/* <CompanyFinancialTableModal
          tableActive={tableActive}
          setTableActive={setTableActive}
          setChartActive={setChartActive}
          sector={sectorData}
          lable={lable}
          setCompanyName={setCompanyName}
          setLable={setLable}
          setchartData={setchartData}
        /> */}
      </>
    );
  } else {
    var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    return (
      <div className="ask-table-format">
        <table className="table table-responsive">
          <thead>
            <tr>
              <th colSpan={7} className="text-left">
                Company Financials
              </th>
            </tr>
          </thead>
          <tbody>
            {arr.map((v, i) => {
              return (
                <tr key={i}>
                  <td colSpan={7}>
                    <span style={{ color: 'white' }}>.</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={styles.noContent} className="no-content-load text-center">
          <img style={styles.noContentImage} src={loader} alt="" />
        </div>
      </div>
    );
  }
};

export default CompFinancials;
const styles = {
  noContent: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    transform: 'translateX(calc(0% - 0px))',
    width: '100%',
    paddingLeft: '44%',
    paddingRight: '44%',
    display: 'inline-flex',
    alignItems: 'center',
    // justifyContent: 'center',
    zIndex: '999',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};
