import React, { useEffect, useState } from "react";
import LoadingImage from "../../../assets/media/loader.gif";
import Nav from "../nav";
import Header from "../../../common/header/header";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import {
  baseUrl,
  modal_excel_icon,
  modal_chart_icon,
} from "../../../api/baseUrl";
import { valueCheckGlobal } from "../../../GlobalFunctions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ComparisonChartModal from "../../../layout/modals/ComparisonChartModal";
import { useParams, NavLink } from "react-router-dom";

const Energy = (props) => {
  let { sector } = useParams();
  const [energy, setMmc] = useState([{ name: "sulaiman" }]);

  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [heads, setHeads] = useState([]);
  const [table, setTable] = useState([]);
  const [title, setTitle] = useState("");
  const [chartActive, setChartActive] = useState(false);
  const [chartData, setchartData] = useState([]);
  const [label, setLable] = useState("");

  const filteData = (code) => {
    let newArray = allData.filter(function (el) {
      return el.code == code;
    });
    setTable(newArray);
  };

  const head = [
    { year: "2011", key: "data_2011" },
    { year: "2012", key: "data_2012" },
    { year: "2013", key: "data_2013" },
    { year: "2014", key: "data_2014" },
    { year: "2015", key: "data_2015" },
    { year: "2016", key: "data_2016" },
    { year: "2017", key: "data_2017" },
    { year: "2018", key: "data_2018" },
    { year: "2019", key: "data_2019" },
    { year: "2020", key: "data_2020" },
    { year: "2021", key: "data_2021" },
    { year: "2022", key: "data_2022" },
  ];

  const handleChartModal = (chartData) => {
    // console.log(chartData);
    setLable(chartData.description);
    setchartData(chartData);
    setChartActive(true);
  };

  useEffect(() => {
    setIsLoading(false);
    axios
      .get(`${baseUrl}/energyyearbook/${sector}`)
      .then(function (response) {
        setAllData(response.data.data);
        setHeads(response.data.heads);

        setIsLoading(true);
      })
      .catch(function (error) {
        console.log("energyyearbook====>", error);
      });
  }, [sector]);

  const [opened, { open, close }] = useDisclosure(false);

  const year = head[head.length - 1].year;
  const pervious = year - 1;

  const handleModal = (code, title) => {
    filteData(code);
    setTitle(title);
    open();
  };
  console.log(123, sector, isLoading, allData);
  return (
    <>
      <Header />
      <div className="energy-main analyst">
        <div className="row energyboxed" style={styles.navWidth}>
          <Nav nopadding={true} />
        </div>
      </div>

      <div className="dashboard-main container container_box">
        <div className="main_tabs_listing">
          <div className="energy_nav_links">
            <NavLink to="/sector/energy/energysituation">
              Energy Situation
            </NavLink>
            <NavLink to="/sector/energy/oil">Oil</NavLink>
            <NavLink to="/sector/energy/gas">Gas</NavLink>
            <NavLink to="/sector/energy/coal">Coal</NavLink>
            <NavLink to="/sector/energy/electricity">Electricity</NavLink>
            <NavLink to="/sector/energy/renewableenergy">
              Renewable Energy
            </NavLink>
            <NavLink to="/sector/energy/appendices">Appendices</NavLink>
          </div>
        </div>
      </div>

      {allData.length && isLoading ? (
        <>
          <div className="dashboard-main container container_box">
            <div className="main_tabs_listing">
              <div className="col-md-12 pl-0 listing-respons">
                <div className="section_row1">
                  {heads.map((value, key) => {
                    return (
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">{value.code}</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(value.code, value.description)
                              }
                            >
                              {value.description}
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="section_boxes">
              <div className="col-md-12">
                <div className="row"></div>
              </div>
            </div>
          </div>
          <Modal
            className="listing-modal"
            opened={opened}
            onClose={close}
            title={title}
            closeOnClickOutside={false}
          >
                   <span style={styles.excel} className="mb-2">
                <ReactHTMLTableToExcel
                  className="download-table-xls-button"
                  table="table"
                  filename="table"
                  sheet="file"
                  buttonText={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_excel_icon,
                      }}
                    />
                  }
                />{" "}
              </span>
            <div className="energy-table-dta ask-table-format">
              <table className="table table-responsive" id="table">
                <thead>
                  <tr>
                    <th scope="col">Source</th>
                    <th scope="col">Unit</th>
                    <th scope="col">&nbsp;</th>
                    {head.map((value) => {
                      return <th scope="col" className="right-align">{value.year}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {table.length
                    ? table.map((value, index) => {
                        return (
                          <tr>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: value.label,
                                }}
                              />
                            </td>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: value.unit,
                                }}
                              />
                            </td>
                            <td>
                              <span
                                style={styles.chart}
                                onClick={() => {
                                  handleChartModal(value);
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: modal_chart_icon,
                                  }}
                                />
                              </span>
                            </td>

                            {head.map((head_value) => {
                              return (
                                <td className="right-align">
                                  {valueCheckGlobal(
                                    value[head_value.key],
                                    "",
                                    2
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </Modal>
          {chartActive && (
            <ComparisonChartModal
              chartActive={chartActive}
              setChartActive={setChartActive}
              // companyname={companyName}
              label={label}
              chartData={chartData}
              slice={false}
              head={head}
            />
          )}
        </>
      ) : (
        <div
          style={{ width: "100%", height: "100%", color: "#426fd7" }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: "500px" }} />
        </div>
      )}
    </>
  );
};
const styles = {
  chart: {
    cursor: "pointer",
    float: "left",
    posiiton: "absolute",
    padding: "0px 10px 0 10px",
  },
  switchBtn: {
    float: "right",
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    transform: "scale(1.5)",
    marginRight: "23px",
  },
  lables: {
    width: "200px",
    float: "right",
  },
  companylogo: {
    width: "240px",
    float: "right",
  },
  companylogoimage: {
    margin: "auto 0px",
  },
  navWidth: {
    width: "100%",
    fontSize: "14px",
    margin: "auto",
    marginBottom: "10px",
    justifyContent: "space-between",
  },
  companyname: {
    width: "50%",
    fontSize: "14px",
    margin: "auto",
    marginBottom: "10px",
    justifyContent: "space-between",
  },
  noContent: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "0.3",
  },
  noContentImage: {
    width: "70px",
  },
  position: {
    position: "relative",
  },
  excel: {
    cursor: "pointer",
    float: "right",
  },
};
export default Energy;
