import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FinanceIncome from './financeIncome';
import FinanceBalance from './financialBalance';
import FinanceRatio from './financialRatio';
import ChartSlider from './ChartSlider';
import ChartSection from './ChartSection';
import { baseUrl } from '../../../api/baseUrl';
import { useCookies } from 'react-cookie';
const FinancialCharts = (props) => {
  let comp_id = props.company.id;

  const [charts, setCharts] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies();

  useEffect(() => {
    setCharts([]);
    fetch(`${baseUrl}/financialchartnew/${comp_id}?financialchartnew=true`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setCharts(data);
      });
  }, [comp_id]);
  return (
    <>
      <div className="financial-chart col-12 p-0">
        <div className="col-12 data-block p-0">
          {charts.map((section, index) => {
            return (
              <ChartSection
                section={section}
                company={props.company}
                key={index}
                key2={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
export default FinancialCharts;
