import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingImage from '../../../assets/media/loader.gif';
import companylogo from '../../../assets/media/images/akseer-and-alpha-logo.png';
import { baseUrl } from '../../../api/baseUrl';
import Nav from '../nav1';
import Chart from './Chart';
import Header from '../../../common/header/header';

const BOP = (props) => {
  const [chart, setChart] = useState([]);
  const [date, setDate] = useState([]);
  const [bop, setBop] = useState([]);
  const [head, setHead] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const d = new Date();
  let current_date =
    d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear();
    useEffect(() => {
      setBop([]);
    axios
      .get(`${baseUrl}/bop`)
      .then(function (response) {
        const data = JSON.parse(response.data.message);
        setChart(data.chart);
        setDate(response.data.date2);
        setBop(data.data);
        setHead(data.head);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log('inflation====>', error);
      });
  }, []);

  let boldHeadings = [
    'Balance on trade in Goods',
    'Balance on trade in Services',
    'Current A/c Balance',
    'Capital A/C Balance',
    'Balance on Primary Income',
    'Balance on Secondary Income',
    'Financial Account',
    'Net Errors and Omissions',
    'Overall Balance',
  ];
  const headingCheck = (heading) => {
    return boldHeadings.includes(heading);
  };

  const checkMinus = (value, percentage = false) => {
    if (percentage) {
      return value;
    }
    value = value.replace('%', '');
    value = value.replace(',', '');
    var color = '';
    if (value < 0 && value != 'NM') {
      color = 'red';
      value = Math.abs(value);
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      value = '(' + value + ')';
    } else {
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return (
      <span
        style={{ color: color }}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  };

  return (
    <>
      <Header />
      <div className="bop-main analyst">
        <div className="row bopboxed" style={styles.navWidth}>
          <Nav nopadding={true} />
        </div>
      </div>
      {bop.length && isLoading ? (
        <div className="bop-main analyst">
          <div className="container container_box">
            <div className="row">
              <div className="col-md-7 pr-0">
                <div className="row bop_section">
                  <div className="col-sm-10 p-0 title omc_lucky">
                    <p className="heading mb-0 p-0">
                      Akseer Research (Pvt) Ltd.
                    </p>
                    <p className="amount_symbol mb-0 p-0">{date}</p>
                    <p className="heading mb-0 p-0">
                      External Account Highlights
                    </p>
                  </div>
                  <div
                    className="col-sm-2 logo p-0"
                    style={styles.companylogoimage}
                  >
                    <img src={companylogo} style={styles.companylogo} />
                  </div>
                </div>
                <div className="ask-table-format omc-pdg-tbl bop-top-space">
                  <table
                    className="table table-responsive container container_box"
                  >
                    <thead>
                      <tr>
                        {head.map((value, index) => {
                          return <th className="bop-value-rows">{value}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {bop.map((value, index) => {
                        var fontweight = headingCheck(value.label)
                          ? '800'
                          : '0';
                        return (
                          <tr
                            style={{
                              fontWeight: fontweight,
                            }}
                          >
                            <td>{value.label}</td>
                            <td className="right-align">{checkMinus(value.previous_year)}</td>
                            <td className="right-align">{checkMinus(value.previous_month)}</td>
                            <td className="right-align">{checkMinus(value.current_month)}</td>
                            <td className="right-align">{checkMinus(value.MoM, true)}</td>
                            <td className="right-align">{checkMinus(value.YoY, true)}</td>
                            <td className="right-align">{checkMinus(value.Q1)}</td>
                            <td className="right-align">{checkMinus(value.Q2)}</td>
                            <td className="right-align">{checkMinus(value.QYoY, true)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className="container container_box pl-0"
                  >
                    <p>Source: SBP, Akseer Research</p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 pl-0">
                <div className="flow_chart">
                  <Chart chart={chart} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '240px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '50%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
};
export default BOP;