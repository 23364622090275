import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import { baseUrl } from '../../../api/baseUrl';

const SettlementVolume = (props) => {
  let compId = props.company.id;
  let compSymbol = props.company.symbol;
  let chartId = props.chartId;
  let chartvalue = props.chartValue;
  let chartcolor = props.chartColor;
  let legendName = props.legendName;
  const [cookies, setCookies, removeCookies] = useCookies();
  const [destroy, setDestroy] = useState(false);
  const chartRef = useRef();

  useEffect(() => {
    fetch(
      `${baseUrl}/settlementchartnew/${compSymbol}`,
      // `https://askanalyst-testing.akseerresearch.com/api/kseindex/${compId}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${cookies?.accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (chartId !== undefined) {
          generateChart(data);
        }
      });
  }, [props.company.symbol]);
  const generateChart = (data1) => {
    if (destroy === true) {
      chartRef.current.dispose();
    }

    let elementPath = 'setttlement-volume-' + chartId;
    let root = am5.Root.new(elementPath);
    chartRef.current = root;
    root.dateFormatter.setAll({
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['valueX'],
    });
    root.setThemes([am5themes_Animated.new(root)]);

    root.numberFormatter.setAll({
      numberFormat: '#,###a',
      numericFields: ['valueY'],
    });

    setDestroy(true);
    let chartSetting = {};
    if (chartId == 's3') {
      chartSetting = {
        panX: false,
        panY: false,
        // wheelX: 'panX',
        // wheelY: 'zoomX',
        connect: false,
        paddingLeft: 0,
        // padding: (0, 0, 0, 0),
      };
    } else {
      chartSetting = {
        panX: false,
        panY: false,
        // wheelX: 'panX',
        // wheelY: 'zoomX',
        connect: false,
        paddingRight: 0,
        paddingLeft: 0,
        // padding: (0, 0, 0, 0),
      };
    }
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, chartSetting)
    );
    // chart.cursor.behavior = 'none';
    // chart.;
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      })
    );
    cursor.lineY.set('visible', false);

    chart.root._logo._childrenDisplay.visible = false;
    chart._settings.paddingBottom = 25;
    // chart._settings.paddingLeft = 5;

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        gridIntervals: [
          { timeUnit: 'day', count: 1 },
          { timeUnit: 'month', count: 1 },
        ],
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    let xRenderer = xAxis.get('renderer');
    xRenderer.grid.template.setAll({
      strokeOpacity: 0,
      strokeWidth: 0,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 1,
          strokeWidth: 0.2,
        }),
      })
    );

    let yRenderer = yAxis.get('renderer');
    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
      min: yAxis.getPrivate('min'),
      max: yAxis.getPrivate('max'),
      start: 0,
      end: 1,
      stroke: 0,
    });
    let legendPercent = 0;
    if (chartvalue == 'total_volume') {
      // Add series
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Volume Traded',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'total_volume',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: 'Volume Traded: {valueY}',
          }),
        })
      );

      series.set('fill', am5.color('#' + chartcolor));
      series.set('stroke', am5.color('#' + chartcolor));
      series.fills.template.setAll({ fillOpacity: 0.3, visible: true });
      series.strokes.template.setAll({
        strokeWidth: 1,
      });
      series.data.setAll(data1);

      series.appear(1000);
      legendPercent = -50;
    } else {
      let series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: legendName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: chartvalue,
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: chartvalue + ': ' + '{valueY}',
          }),
        })
      );

      series1.set('fill', am5.color('#' + chartcolor));
      series1.set('stroke', am5.color('#' + chartcolor));
      series1.strokes.template.setAll({
        strokeWidth: 1,
      });
      series1.data.setAll(data1);

      series1.appear(1000);

      var y2Renderer = am5xy.AxisRendererY.new(root, {
        opposite: true,
        strokeOpacity: 1,
        strokeWidth: 0.2,
      });
      y2Renderer.grid.template.setAll({
        strokeOpacity: 0,
        start: 0,
        end: 1,
        stroke: 0,
      });
      yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 1,
          renderer: y2Renderer,
        })
      );

      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set('syncWithAxis', chart.yAxes.getIndex(0));
      }

      let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Closing Price',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'Closing Price',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: 'Closing Price: ' + '{valueY}',
          }),
        })
      );

      series2.set('fill', am5.color('#0B254A'));
      series2.set('stroke', am5.color('#0B254A'));
      series2.fills.template.setAll({ fillOpacity: 0.3, visible: true });
      series2.strokes.template.setAll({
        strokeWidth: 1,
      });
      series2.data.setAll(data1);

      series2.appear(1000);
    }

    /* let scrollbar = chart.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 10,
        // wheelable: 10,
        // end: 1,
        // start: 0,
      })
    ); */

    if (chartvalue == 'Closing Price') {
      var exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        dataSource: data1,
        dateFields: ['date'],
        dateFormat: 'yyyy-MM-dd',
        dataFields: {
          'Closing Price': 'Closing Price',
          Vol: 'Volume',
          total_value: 'Total Volume',
          date: 'Date',
        },
        dataFieldsOrder: ['Date', 'Closing Price', 'Volume', 'Total Volume'],
      });

      /* exporting.events.on('dataprocessed', function (ev) {
      for (var i = 0; i < ev.data.length; i++) {
        ev.data[i].sum = ev.data[i].value + ev.data[i].value2;
      }
    }); */

      exporting.get('menu').set('items', [
        {
          type: 'format',
          format: 'jpg',
          label: 'JPEG',
          iconTag: 'image.jpg',
        },
        {
          type: 'format',
          format: 'xlsx',
          label: 'Excel',
        },
        {
          type: 'format',
          format: 'pdf',
          label: 'PDF',
        },
      ]);
    }

    // zunair.nusrat@gmail.com
    // Og36^5

    // chart.exporting.menu.items[0].icon = Pdfsvg;

    var legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'categoryX',
        x: am5.percent(30),
        y: am5.percent(100),
        centerX: am5.percent(legendPercent),
        centerY: am5.percent(0),
      })
    );
    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);
  };

  return (
    <div
      id={'setttlement-volume-' + chartId}
      className="settlement-chart"
      key={chartId}
    ></div>
  );
};

export default SettlementVolume;
