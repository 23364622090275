// import React, { useState } from 'react';
// import { useFormik } from 'formik';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { loginAPI, signupAPI } from '../../api/api';
// import { Link, Redirect, useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import eye from '../../assets/media/icons/eye.svg';
// import { number } from 'yup';
// import SuccessModal from './successModal';

// // Validation Errors

// /*
// name field 
// 1 non empty
// 2 no space in begining
// 3 length minimum 3
// 4 alphabets char only with space allowed in between 
// */

// /*
// email field 
// 1 non empty
// 2 email pattern verified
// */

// /*
// password field 
// 1 non empty
// 2 pattern matching
// 3 char length 
// 4 additional special characters allowed 
// */

// /*
// confirm password field 
// 1 non empty
// 2 same as password field
// */

// const validate = (values) => {
//   const errors = {};

//   if (!values.name) {
//     errors.name = 'Name is Required';
//   } else if (values.name) {
//     if (values.name[0] === ' ') {
//       errors.name = 'Please remove spaces from the begining';
//     } else if (values.name.length <= 2) {
//       errors.name = 'Please enter atleast 3 characters(alphabets only)';
//     } else if (!/^[A-Za-z\s]+$/i.test(values.name)) {
//       errors.name = 'Name includes alphabets only.';
//     } else {
//       if (!values.email) {
//         errors.email = 'Email is Required';
//       } else if (values.email) {
//         if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//           errors.email = 'Invalid email address';
//         } else {
//           if (!values.password) {
//             errors.password = 'Password is Required';
//           } else if (values.password) {
//             if (
//               !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#^$%&_`~=/><,;:"'\^\+\-\.\*\(\)\|\[\]\{\}\\])[A-Za-z\d!@#$%&_`~=/?><,;:"'\^\+\-\.\*\(\)\|\[\]\{\}\\]{6,}$/i.test(
//                 values.password
//               )
//             ) {
//               errors.password =
//                 'Password must be at least 6 characters including Alphanumeric and Special character';
//             } else if (values.password.length <= 5) {
//               console.log(values.password.length, 'values.password.length');
//               errors.password = 'Password length atleast 6 characters';
//             } else {
//               if (!values.password_confirmation) {
//                 errors.password_confirmation =
//                   'Please type your password again';
//               } else if (values.password_confirmation) {
//                 if (values.password !== values.password_confirmation) {
//                   errors.password_confirmation = 'Password does not match';
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   return errors;
// };

// // Main Component

// export default function _signup(props) {
//   const [able, setAble] = useState(false);
//   const [view, setView] = useState(false);
//   const [successModal, setSuccessModal] = useState(false);
//   const site = props.site;

//   // const isAuthenticated = localStorage.getItem("isAuthenticated");

//   const dispatch = useDispatch();
//   const history = useHistory();

//   // Function to submit values
//   // let headers = {'Access-Control-Allow-Origin': '*'}

//   const handleSubmit = (values) => {
//     setAble(true);
//     axios
//       .post(signupAPI, values, {
//         headers: { 'Access-Control-Allow-Origin': '*' },
//       })
//       .then(function (response) {
//         // toast.success("Registered successful, Please find the verification link in your registered email address.",
//         // {
//         //   autoClose: 15000,
//         // });
//         setSuccessModal(true);
//         // history.push('/');
//       })
//       .catch(function (error) {
//         setTimeout(() => {
//           setAble(false);
//         }, 3000);
//         if (error.response.status === 400) {
//           toast.error(
//             JSON.parse(error.response.data).email[0] +
//               ' Please try with unique one.',
//             {
//               hideProgressBar: true,
//               autoClose: 3500,
//             }
//           );
//         } else {
//           console.log(error);
//         }
//       });
//   };

//   const closeModal = () => {
//     setSuccessModal(false);
//   };

//   const formik = useFormik({
//     initialValues: {
//       name: '',
//       email: '',
//       password: '',
//       password_confirmation: '',
//       site: site,
//     },
//     validate,
//     onSubmit: (values) => {
//       handleSubmit(values);
//     },
//   });

//   return (
//     <>
//       <form className="form" onSubmit={formik.handleSubmit}>
//         <input
//           name="type"
//           type="hidden"
//           onChange={formik.handleChange}
//           value={site}
//         />
//         <div className="form-group">
//           <label htmlFor="email">Name</label>

//           <input
//             className={`form-control`}
//             placeholder="Enter your name"
//             id="name"
//             name="name"
//             type="name"
//             onChange={formik.handleChange}
//             value={formik.values.name}
//           />

//           {formik.errors.name ? (
//             <span className="text-danger">{formik.errors.name}</span>
//           ) : null}
//         </div>
//         <div className="form-group">
//           <label htmlFor="email">Email</label>

//           <input
//             className={`form-control`}
//             placeholder="Enter your email"
//             id="email"
//             name="email"
//             type="email"
//             onChange={formik.handleChange}
//             value={formik.values.email}
//           />

//           {formik.errors.email ? (
//             <span className="text-danger">{formik.errors.email}</span>
//           ) : null}
//         </div>
//         <div className="form-group">
//           <label htmlFor="password">Password</label>
//           <input
//             className={`form-control`}
//             placeholder="Enter your password"
//             id="password"
//             name="password"
//             type={view ? 'text' : 'password'}
//             onChange={formik.handleChange}
//             value={formik.values.password}
//           />
//           {formik.errors.password ? (
//             <span className="text-danger"> {formik.errors.password} </span>
//           ) : null}
//         </div>
//         <div className="form-group">
//           <label htmlFor="password">Confirm Password</label>
//           <input
//             className={`form-control`}
//             placeholder="Enter your password"
//             id="password_confirmation"
//             name="password_confirmation"
//             type={view ? 'text' : 'password'}
//             onChange={formik.handleChange}
//             value={formik.values.password_confirmation}
//           />
//           {formik.values.password_confirmation.length > 0 ? (
//             <span className="eye-icon" onClick={() => setView(!view)}>
//               <img src={eye} alt="view" />
//             </span>
//           ) : null}
//           {formik.errors.password_confirmation ? (
//             <span className="text-danger">
//               {' '}
//               {formik.errors.password_confirmation}{' '}
//             </span>
//           ) : null}
//         </div>
//         <button className="btn m-0" type="submit" disabled={able}>
//           Sign Up
//         </button>
//       </form>
//       {/* <div className="form-group d-flex">
//           <span className="forgot-btn ml-1"><small className='mr-2'>Already a member?</small> <Link to="/">Sign In</Link></span>
//         </div> */}
//       <hr className="seperator mt-4" />
//       <div className="d-inline-flex flex-wrap align-items-center justify-content-center w-100">
//         <p className="mr-2 mb-0 w-100 text-center" style={styles.memberText}>
//           Already a member?
//         </p>
//         <button
//           className="btn btn-primary secondary mt-2 w-100"
//           style={{
//             border: '1px solid #3969d5',
//             background: 'transparent',
//             minWidth: '100px',
//             minHeight: '40px',
//           }}
//         >
//           <Link
//             to="/"
//             style={{
//               color: '#3969d5',
//               textDecoration: 'none',
//               display: 'inline-block',
//               width: '100%',
//             }}
//           >
//             Sign In
//           </Link>
//         </button>
//       </div>
//       <SuccessModal successModal={successModal} closeModal={closeModal} />
//     </>
//   );
// }

// const styles = {
//   memberText: {
//     color: '#3d3d3d',
//   },
// };







import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { signupAPI } from '../../api/api';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import eye from '../../assets/media/icons/eye.svg';
import SuccessModal from './successModal';

// Validation Errors
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name is Required';
  } else if (values.name) {
    if (values.name[0] === ' ') {
      errors.name = 'Please remove spaces from the beginning';
    } else if (values.name.length <= 2) {
      errors.name = 'Please enter at least 3 characters (alphabets only)';
    } else if (!/^[A-Za-z\s]+$/i.test(values.name)) {
      errors.name = 'Name includes alphabets only';
    } else if (!values.email) {
      errors.email = 'Email is Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    } else if (!values.password) {
      errors.password = 'Password is Required';
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#^$%&_`~=/><,;:"\'\^\+\-\.\*\(\)\|\[\]\{\}\\])[A-Za-z\d!@#$%&_`~=/?><,;:"\'\^\+\-\.\*\(\)\|\[\]\{\}\\]{6,}$/i.test(values.password)) {
      errors.password = 'Password must be at least 6 characters including Alphanumeric and Special characters';
    } else if (values.password.length <= 5) {
      errors.password = 'Password length must be at least 6 characters';
    } else if (!values.password_confirmation) {
      errors.password_confirmation = 'Please confirm your password';
    } else if (values.password !== values.password_confirmation) {
      errors.password_confirmation = 'Passwords do not match';
    }
  }
  return errors;
};

export default function _signup(props) {
  const [able, setAble] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const site = props.site;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (values) => {
    setAble(true);
    axios
      .post(signupAPI, values)
      .then((response) => {
        setSuccessModal(true);
      })
      .catch((error) => {
        setAble(false);
        if (error.response && error.response.status === 400) {
          toast.error('Email already exists. Please try with a unique one.');
        }
      });
  };

  const closeModal = () => {
    setSuccessModal(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      site: site,
    },
    validate,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <form className="form" onSubmit={formik.handleSubmit}>
        <input name="type" type="hidden" value={site} />
        
        {/* Name Field */}
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            className="form-control"
            placeholder="Enter your name"
            id="name"
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name && <span className="text-danger">{formik.errors.name}</span>}
        </div>

        {/* Email Field */}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            className="form-control"
            placeholder="Enter your email"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && <span className="text-danger">{formik.errors.email}</span>}
        </div>

        {/* Password Field */}
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            className="form-control"
            placeholder="Enter your password"
            id="password"
            name="password"
            type={viewPassword ? 'text' : 'password'}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <span className="eye-icon" onClick={() => setViewPassword(!viewPassword)}>
            <img src={eye} alt="view" />
          </span>
          {formik.errors.password && <span className="text-danger">{formik.errors.password}</span>}
        </div>

        {/* Confirm Password Field */}
        <div className="form-group">
          <label htmlFor="password_confirmation">Confirm Password</label>
          <input
            className="form-control"
            placeholder="Confirm your password"
            id="password_confirmation"
            name="password_confirmation"
            type={viewConfirmPassword ? 'text' : 'password'}
            onChange={formik.handleChange}
            value={formik.values.password_confirmation}
          />
          <span className="eye-icon" onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
            <img src={eye} alt="view" />
          </span>
          {formik.errors.password_confirmation && <span className="text-danger">{formik.errors.password_confirmation}</span>}
        </div>

        <button className="btn mt-0" type="submit" disabled={able}>
          Sign Up
        </button>
      </form>
      <hr className="seperator mt-4" />
       <div className="d-inline-flex flex-wrap align-items-center justify-content-center w-100">
         <p className="mr-2 mb-0 w-100 text-center" style={styles.memberText}>
           Already a member?
         </p>
         <button
          className="btn btn-primary secondary mt-2 w-100"
          style={{
            border: '1px solid #3969d5',
            background: 'transparent',
            minWidth: '100px',
            minHeight: '40px',
          }}
        >
          <Link
            to="/"
            style={{
              color: '#3969d5',
              textDecoration: 'none',
              display: 'inline-block',
              width: '100%',
            }}
          >
            Sign In
          </Link>
        </button>
      </div>
      <SuccessModal successModal={successModal} closeModal={closeModal} />
    </>
  );
}

const styles = {
  memberText: {
    color: '#3d3d3d',
  },
};