import React, { useState, useEffect } from 'react';
import Nav from './nav.jsx';
import IdleTimer from '../../IdleTimerContainer';
import Header from '../../common/header/header';
import LoadingImage from '../../assets/media/loader.gif';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { baseUrl, pdf, loader } from '../../api/baseUrl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { modal_close_icon, modal_excel_icon } from '../../api/baseUrl';
import Chart from './Chart';
import Switch from 'react-input-switch';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { line_grey } from '../../api/baseUrl';
import { line_blue } from '../../api/baseUrl';
import { label_grey } from '../../api/baseUrl';
import { label_blue } from '../../api/baseUrl';
import { eye_grey } from '../../api/baseUrl';
import { color_icon } from '../../api/baseUrl';
import { eye_blue } from '../../api/baseUrl';
import { close } from '../../api/baseUrl';
import { tags_unvisible_icon } from '../../api/baseUrl';
import { bar_grey } from '../../api/baseUrl';
import { bar_blue } from '../../api/baseUrl';
import ApexCharts from 'apexcharts';
import {
  Slider,
  RangeSlider,
  Tooltip,
  ColorInput,
  Radio,
  rem,
  SegmentedControl,
} from '@mantine/core';
import {
  YearPickerInput,
  MonthPickerInput,
  DatePickerInput,
} from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { DatePicker, ColorPicker } from 'antd';
import dayjs from 'dayjs';
function subtractYears(date, years) {
  date.setFullYear(date.getFullYear() - years);

  let month = ('0' + (date.getMonth() + 1)).slice(-2); //months from 1-12
  let day = ('0' + date.getDate()).slice(-2);
  let year = date.getFullYear();

  let newdate = year + '/' + month + '/' + day;
  return newdate;
}
const current_year = new Date().getFullYear();
const current_month = ('0' + (new Date().getMonth() + 1)).slice(-2);
const current_day = ('0' + new Date().getDate()).slice(-2);
const current_date = current_year + '/' + current_month + '/' + current_day;
const one_year_ago = subtractYears(new Date(), 1);
// console.log('000', one_year_ago, current_date);

const { RangePicker } = DatePicker;

const animatedComponents = makeAnimated();

function Index(props) {
  const history = useHistory();
  const cities = [
    { value: 'islamabad', label: 'Islamabad' },
    { value: 'rawalpindi', label: 'Rawalpindi' },
    { value: 'gujranwala', label: 'Gujranwala' },
    { value: 'sialkot', label: 'Sialkot' },
    { value: 'lahore', label: 'Lahore' },
    { value: 'faislabad', label: 'Faislabad' },
    { value: 'sargodha', label: 'Sargodha' },
    { value: 'multan', label: 'Multan' },
    { value: 'bahawalpur', label: 'Bahawalpur' },
    { value: 'karachi', label: 'Karachi' },
    { value: 'hyderabad', label: 'Hyderabad' },
    { value: 'sukkur', label: 'Sukkur' },
    { value: 'larkana', label: 'Larkana' },
    { value: 'peshawar', label: 'Peshawar' },
    { value: 'bannu', label: 'Bannu' },
    { value: 'quetta', label: 'Quetta' },
    { value: 'khuzdar', label: 'Khuzdar' },
    { value: 'national', label: 'National' },
  ];
  const cities_cng = [
    { value: 'islamabad', label: 'Islamabad' },
    { value: 'rawalpindi', label: 'Rawalpindi' },
    { value: 'gujranwala', label: 'Gujranwala' },
    { value: 'sialkot', label: 'Sialkot' },
    { value: 'lahore', label: 'Lahore' },
    { value: 'faislabad', label: 'Faislabad' },
    { value: 'sargodha', label: 'Sargodha' },
    { value: 'multan', label: 'Multan' },
    { value: 'bahawalpur', label: 'Bahawalpur' },
    { value: 'karachi', label: 'Karachi' },
    { value: 'hyderabad', label: 'Hyderabad' },
    { value: 'sukkur', label: 'Sukkur' },
    { value: 'larkana', label: 'Larkana' },
    { value: 'peshawar', label: 'Peshawar' },
    { value: 'bannu', label: 'Bannu' },
    { value: 'quetta', label: 'Quetta' },
    { value: 'khuzdar', label: 'Khuzdar' },
  ];
  const cities_fertilizer = [
    { value: 'rawalpindi', label: 'Rawalpindi' },
    { value: 'gujranwala', label: 'Gujranwala' },
    { value: 'lahore', label: 'Lahore' },
    { value: 'faislabad', label: 'Faislabad' },
    { value: 'sargodha', label: 'Sargodha' },
    { value: 'multan', label: 'Multan' },
    { value: 'bahawalpur', label: 'Bahawalpur' },
    { value: 'hyderabad', label: 'Hyderabad' },
    { value: 'sukkur', label: 'Sukkur' },
    { value: 'larkana', label: 'Larkana' },
    { value: 'peshawar', label: 'Peshawar' },
    { value: 'bannu', label: 'Bannu' },
    { value: 'quetta', label: 'Quetta' },
  ];
  const icon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  const [sessionMessage, setSessionMessage] = useState('');
  const [sessionModal, setSessionModal] = useState(false);
  const [dark, setDark] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoading2, setIsLoading2] = useState(true);
  const accessToken = cookies.accessToken;
  const [loaderIcon, setLoaderIcon] = useState(false);
  const [searchList, setSearchList] = useState(null);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState({
    value: 'Jun',
    label: 'Jun',
  });
  const [city, setCity] = useState([]);
  const [classification, setClassification] = useState([]);
  const [avg, setAvg] = useState([]);
  const [avgSelector, setAvgSelector] = useState(true);
  const [records, setRecords] = useState([]);
  const [cityOptions, setCityOptions] = useState(cities);
  const [frequency, setFrequency] = useState('Day');
  const [series, setSeries] = useState([]);
  const [dates, setDates] = useState([]);
  const [axis, setAxis] = useState([]);
  const [chartType, setChartType] = useState('line');
  const [rangeValue, setRangeValue] = useState([80, 100]);
  const [colors, setColors] = useState([]);
  const [strock, setStrock] = useState([]);
  // const [timePeriod, setTimePeriod] = useState(rangeValue);
  const [btnName, setBtnName] = useState('Load Chart');

  const [value, setValue] = useState([null, null]);

  const [timePeriod, setTimePeriod] = useState([one_year_ago, current_date]);

  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${cookies?.accessToken}`;
  const average = [
    { value: 'min', label: 'Min' },
    { value: 'max', label: 'Max' },
    { value: 'average', label: 'Average' },
  ];

  const handleShow = () => setShow(true);

  // useEffect(() => {}, [color]);
  useEffect(() => {
    const list = fetch(`${baseUrl}/searchlist`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSearchList(data);
        setIsLoading(true);
        // const companyListIndex = data.filter((p) => p.value == symbol);
        // setCompanyListIndex(companyListIndex);
        // console.log(123, data, companyListIndex);
      });
  }, []);

  const dateFormat = 'YYYY-MM-DD';

  const handleCityChange = (city) => {
    setCity(city);
    // console.log(city);
  };
  const handleAverageChange = (average) => {
    setAvg(average);
    // console.log(average);
  };

  var handleChange = (selectedOption, context) => {
    //console.log(selectedOption);
    if (context.action == 'remove-value') {
      setSelectedItem(selectedOption);
      setShow(false);
      return;
    }
    if (selectedOption.length > 0) {
      setSelectedItem(selectedOption);
      let lastElement = selectedOption[selectedOption.length - 1];
      let lastElementArray = lastElement.value.split('_');
      // if (lastElement.label.indexOf('SPI') > -1) {
      setClassification(lastElementArray[1]);
      if (lastElementArray[1] == 'SPI') {
        // console.log(lastElementArray[1], lastElementArray[0]);
        setAvgSelector(false);
        if (lastElementArray[2] == 'Essential') {
          setAvgSelector(true);
          setCityOptions(cities);
        } else if (lastElementArray[2] == 'Fertilizer') {
          setCityOptions(cities_fertilizer);
        } else {
          setCityOptions(cities_cng);
        }
        setTitle(lastElement.label);
        setShow(true);
        // console.log(123, lastElement);
      }
    }
  };

  const handleSPIClose = () => {
    if (selectedItem.length > 0) {
      // setSelectedItem(selectedOption);
      const lastElement = selectedItem.length;
      let lastElementIndex = selectedItem[lastElement - 1];
      setSelectedItem(selectedItem.splice(0, lastElement - 1));
      // console.log(lastElement - 1, lastElementIndex, lastElement, selectedItem);
    }
    setShow(false);
  };

  const ApplySPI = () => {
    // selectedItem;

    const lastElement = selectedItem.length;
    let lastElementIndex = selectedItem[lastElement - 1];

    // obj = {};
    lastElementIndex['city'] = city.value;
    lastElementIndex['avg'] = avg.value;
    // console.log(lastElementIndex, selectedItem);
    setShow(false);
    // let lastElement = selectedOption[selectedOption.length - 1];
  };

  const handleSwitch = (value) => {
    setChartType(value);
  };

  const _handleSubmit = () => {
    setRecords([]);
    // const [records, setRecords] = useState([]);

    axios
      .post(`${baseUrl}/inflation`, {
        data: selectedItem,
        classification: classification,
        frequency: frequency,
        timePeriod: timePeriod,
        selectedMonth: selectedMonth.value,
      })
      .then(function (response) {
        setRecords(response.data.table);
        // console.log(response.data.table[0]['data'].length);
        setSeries(response.data.chart);
        setDates(response.data.dates);
        setAxis(response.data.axis);
        setColors(response.data.colors);
        setStrock(response.data.strock);
        setLoaderIcon(false);
        setBtnName('Reload Chart');

        // console.log('here', response.data.table);
        // console.log('inflation data', response.data.table);
        // setCharts(response.data);
      })
      .catch(function (error) {
        console.log('inflation====>', error);
      });
  };
  const handleSubmit = () => {
    // console.log(selectedItem);
    setLoaderIcon(true);

    _handleSubmit();
  };

  const handleTimePeriod = (e) => {
    // console.log(e.target.value, selectedItem);
    setTimePeriod(e.target.value);
    if (selectedItem.length > 0) {
      _handleSubmit();
    }
    // setCurrentPage(1);
    // setPostsPerPage(e.target.value);
  };

  const handleSeries = (seriesName) => {
    // console.log(e.target.seriesname);
    ApexCharts.exec('graphID', 'toggleSeries', seriesName);
  };

  const handleChartType = (index, type) => {
    series[index]['type'] = type;
    setSeries(series);
    strock[index] = type == 'bar' ? 0 : 2;
    setStrock(strock);
    var stroks = {
      stroke: {
        show: true,
        curve: 'smooth',
        width: strock,
      },
    };

    ApexCharts.exec('graphID', 'updateOptions', stroks);

    ApexCharts.exec('graphID', 'updateSeries', series, true);
  };
  const arr = [];
  const handleDataLable = (index) => {
    if (arr.includes(index)) {
      arr.pop(index);
    } else {
      arr.push(index);
    }
    var lebel = {
      dataLabels: {
        enabled: true,
        position: 'top',
        enabledOnSeries: arr,
      },
    };
    ApexCharts.exec('graphID', 'updateOptions', lebel);
  };

  const handleClose = (index) => {
    // delete series[index];
    series.splice(index, 1);
    const index2 = series.indexOf(index);
    setSeries(series);

    ApexCharts.exec('graphID', 'updateSeries', series, true);
    console.log(index, records, series);
  };
  const handleRangeValue = (value) => {
    setRangeValue(value);
    setTimePeriod(value);
    console.log(value);
  };

  const handleSeriesColor = (e, index) => {
    // console.log(e.toHexString());
    colors[index] = e.toHexString();
    var color = {
      fill: {
        colors: colors,
      },
    };

    ApexCharts.exec('graphID', 'updateOptions', color);
    // console.log(e.metaColor.originalInput, index);
  };
  const handleRangePicker = (date, dateString) => {
    // setRangeValue(value);
    setTimePeriod(dateString);
    // console.log(date, dateString);
  };

  const handleDate = (dateString) => {
    // setRangeValue(value);
    setTimePeriod(dateString);
    // console.log(dateString);
  };

  const handleFrequency = (value) => {
    // console.log(123, value);
    setFrequency(value);
  };

  var handleFYAndHY = (selectedOption, context) => {
    setSelectedMonth(selectedOption);
  };
  const monthsFYAndHY = [
    { value: 'Mar', label: 'Mar' },
    { value: 'Jun', label: 'Jun' },
    { value: 'Sep', label: 'Sep' },
  ];

  return (
    <>
      <Header dark={dark} setDark={setDark} />

      {/* <Nav /> */}
      {isLoading && searchList ? (
        <>
          <div className="dashboard-main container container_box">
            <div className="main-tabs-view">
              <div className="row">
                <div className="col-md-4">
                  <div className="radio_box">
                    <h1 className="heading_radio">
                      Step 1: Select Data Frequency
                    </h1>
                    <div className="radio_data radio_tabs">
                      <SegmentedControl
                        value={frequency}
                        onChange={handleFrequency}
                        data={[
                          { label: 'Daily', value: 'Day' },
                          { label: 'Weekly', value: 'Week' },
                          { label: 'Monthly', value: 'Month' },
                          { label: 'Quarterly', value: 'Quarter' },
                          { label: 'Half Yearly', value: 'Half Year' },
                          { label: 'Financial Year', value: 'Financial Year' },
                          { label: 'Calendar Year', value: 'Calendar Year' },
                        ]}
                      />
                      {/* <Radio.Group
                        value={frequency}
                        onChange={handleFrequency}
                        name="frequency"
                        label=""
                        description=""
                        withAsterisk
                      >
                        <Radio value="Day" label="Daily" />
                        <Radio value="Week" label="Weekly" />
                        <Radio value="Month" label="Monthly" />
                        <Radio value="Quarter" label="Quarterly" />
                        <Radio value="Half Year" label="Half Yearly" />
                        <Radio value="Annual" label="Fiscal Year" />
                        <Radio value="Calendar Year" label="Calendar Year" />
                      </Radio.Group> */}
                    </div>
                    {frequency == 'Financial Year' ||
                    frequency == 'Half Year' ? (
                      <div className="select-options">
                        <div className="main_year_drop">
                          <h1>Year End:</h1>
                          <Select
                            onChange={handleFYAndHY}
                            options={monthsFYAndHY}
                            className="multi-select1"
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            defaultValue={selectedMonth}
                            // isMulti
                            isClearable={false}
                            // required={true}
                            // hideSelectedOptions={false}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* <div className="datalines"></div> */}
                  <div className="range_selected m-auto">
                    <h1 className="heading_radio">
                      Step 2: Select Date / Period Range
                    </h1>
                    {/* {console.log(123, frequency)} */}
                    {frequency == 'Annual' ||
                    frequency == 'Half Year' ||
                    frequency == 'Financial Year' ||
                    frequency == 'Calendar Year' ||
                    frequency == 'Quarter' ? (
                      <YearPickerInput
                        leftSection={icon}
                        leftSectionPointerEvents="none"
                        type="range"
                        label=""
                        placeholder="Pick dates range"
                        // value={value}
                        clearable
                        defaultValue={[
                          dayjs(one_year_ago, dateFormat),
                          dayjs(current_date, dateFormat),
                        ]}
                        // defaultValue={[new Date(), new Date()]}
                        onChange={handleDate}
                        valueFormat="YYYY"
                      />
                    ) : frequency == 'Month' ? (
                      <MonthPickerInput
                        leftSection={icon}
                        leftSectionPointerEvents="none"
                        type="range"
                        label=""
                        placeholder="Pick dates range"
                        // value={value}
                        clearable
                        defaultValue={[
                          dayjs(one_year_ago, dateFormat),
                          dayjs(current_date, dateFormat),
                        ]}
                        // defaultValue={[new Date(), new Date()]}
                        onChange={handleDate}
                        valueFormat="MMM, YYYY"
                      />
                    ) : frequency == 'Day' || frequency == 'Week' ? (
                      <DatePickerInput
                        leftSection={icon}
                        leftSectionPointerEvents="none"
                        type="range"
                        label=""
                        placeholder="Pick dates range"
                        // value={value}
                        clearable
                        defaultValue={[
                          dayjs(one_year_ago, dateFormat),
                          dayjs(current_date, dateFormat),
                        ]}
                        // defaultValue={[new Date(), new Date()]}
                        onChange={handleDate}
                        valueFormat="MMM, DD YYYY"
                        // valueormat={dateFormat}
                      />
                    ) : (
                      <RangePicker
                        defaultValue={[
                          dayjs(one_year_ago, dateFormat),
                          dayjs(current_date, dateFormat),
                        ]}
                        onChange={(date, dateString) =>
                          handleRangePicker(date, dateString)
                        }
                        // onChange={handleRangePicker}
                        format={dateFormat}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <h1 className="heading_radio">Step 3: Select Data Series</h1>
                  <div className="title-block align-items-center flex-wrap">
                    <div className="mainsearch">
                      <div className="search-container1">
                        <Select
                          onChange={handleChange}
                          options={searchList}
                          className="multi-select"
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          value={selectedItem}
                          isMulti
                          isClearable={false}
                          // required={true}
                          // hideSelectedOptions={false}
                        />
                      </div>
                    </div>
                    <div className="multiple-mai">
                      <div className="multiple-content">
                        <p>*you may select multiple data series</p>
                      </div>
                      <div className="databankbtn">
                        <button className="mt-0" onClick={handleSubmit}>
                          {loaderIcon ? (
                            <span
                              style={{ float: 'left' }}
                              dangerouslySetInnerHTML={{ __html: loader }}
                            ></span>
                          ) : (
                            ''
                          )}
                          {btnName}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* {records.length ? console.log(records[0]['data'].length) : '0'} */}
              {records.length ? (
                <div className="table-container1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-5 colume_table">
                        <span style={styles.excel}>
                          <h1 className="download-text">Download</h1>
                          <ReactHTMLTableToExcel
                            className="download-table-xls-button"
                            table="table"
                            filename="table"
                            sheet="file"
                            buttonText={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: modal_excel_icon,
                                }}
                              />
                            }
                          />{' '}
                        </span>
                        <div className="main_databank">
                          <table
                            className="table table-responsive  sector-table data_table"
                            id="table"
                            style={
                              records[0]['data'].length == 1 ||
                              records[0]['data'].length == 2
                                ? { width: '-webkit-fill-available' }
                                : { width: 'max-content' }
                            }
                          >
                            <thead>
                              <tr>
                                <th className="text-left">
                                  Short Name: &nbsp;
                                </th>
                                {records[0].data.map((value, index) => {
                                  return (
                                    <th className={index + '_th'}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: value['head2'][0],
                                        }}
                                      />
                                    </th>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th className="text-left">Source: &nbsp;</th>
                                {records[0].data.map((value, index) => {
                                  return (
                                    <th className={index + '_th'}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: value['head2'][1],
                                        }}
                                      />
                                    </th>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th className="text-left">Unit: &nbsp;</th>
                                {records[0].data.map((value, index) => {
                                  return (
                                    <th className={index + '_th'}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: value['head2'][2],
                                        }}
                                      />
                                    </th>
                                  );
                                })}
                              </tr>
                              <tr>
                                <th className="date_period">
                                  Date/Period End: &nbsp;
                                </th>
                                {records[0].data.map((value, index) => {
                                  return (
                                    <th className={index + '_th'}>
                                      <Link className="details_links" to="/">
                                        Detail
                                      </Link>
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {records.map((value, index) => {
                                return (
                                  <tr>
                                    <td>{value.date}</td>
                                    {value.data.map((value2, index2) => {
                                      return (
                                        <td className={index + '_td'}>
                                          {value2.value}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                              {/* <tr>
                              <td></td>
                            </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-7 pl-0 pr-0">
                        <div className="table-image">
                          <div className="Data_boxed">
                            {series.map((value, index) => {
                              return (
                                <div className="Data_Series mb-2">
                                  {/* <div className="border_circle"></div> */}
                                  <p>{value.name}</p>
                                  <div className="social_symbol">
                                    <Tooltip label="Bar" color="blue" withArrow>
                                      <button
                                        className="bar_grey"
                                        onClick={() =>
                                          handleChartType(index, 'bar')
                                        }
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: bar_grey,
                                          }}
                                        />
                                      </button>
                                    </Tooltip>
                                    <Tooltip
                                      label="Line"
                                      color="blue"
                                      withArrow
                                    >
                                      <button
                                        className="line_grey"
                                        onClick={() =>
                                          handleChartType(index, 'line')
                                        }
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: line_grey,
                                          }}
                                        />
                                      </button>
                                    </Tooltip>
                                    <Tooltip
                                      label="Data Lable"
                                      color="blue"
                                      withArrow
                                    >
                                      <button
                                        className="label_grey"
                                        onClick={() => handleDataLable(index)}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: label_grey,
                                          }}
                                        />
                                      </button>
                                    </Tooltip>

                                    <Tooltip
                                      label="Hide"
                                      color="blue"
                                      withArrow
                                    >
                                      <button
                                        className="eye_grey"
                                        onClick={() => handleSeries(value.name)}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: eye_grey,
                                          }}
                                        />
                                      </button>
                                    </Tooltip>
                                    <Tooltip
                                      label="Color"
                                      color="blue"
                                      withArrow
                                    >
                                      <ColorPicker
                                        // style={{
                                        //   backgroundColor: colors[index],
                                        // }}
                                        size="small"
                                        defaultValue={colors[index]}
                                        // value={color}
                                        // onChange={setColor}
                                        onChange={(e) => {
                                          handleSeriesColor(e, index);
                                        }}
                                        presets={[
                                          {
                                            label: 'Recommended',
                                            colors: [
                                              '#002060',
                                              '#7F7F7F',
                                              '#FADB14',
                                              '#8BBB11',
                                              '#52C41A',
                                              '#13A8A8',
                                              '#1677FF',
                                              '#2F54EB',
                                              '#722ED1',
                                              '#EB2F96',
                                            ],
                                          },
                                        ]}
                                      >
                                        <Tooltip
                                          label="Color"
                                          color="blue"
                                          withArrow
                                        >
                                          <button className="color_icon">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: color_icon,
                                              }}
                                            />
                                          </button>
                                        </Tooltip>
                                      </ColorPicker>
                                    </Tooltip>
                                    <Tooltip
                                      label="Close"
                                      color="blue"
                                      withArrow
                                    >
                                      <button
                                        className="close"
                                        onClick={() => handleClose(index)}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: close,
                                          }}
                                        />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <Chart
                            series={series}
                            dates={dates}
                            axis={axis}
                            type={chartType}
                            colors={colors}
                            strock={strock}
                            // type="bar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedItem.length && records.length ? (
                <div
                  style={{ width: '100%', height: '100%', color: '#426fd7' }}
                  className="main-loader d-flex flex-column justify-content-center align-items-center"
                >
                  <img src={LoadingImage} style={{ height: '500px' }} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <Modal
            style={styles.modalBackdrop}
            show={show}
            // onHide={handleSPIClose}
            animation={true}
            backdrop="static"
            className="table-modal databank-modal"
          >
            <Modal.Header style={styles.modalHeader}>
              <Modal.Title className="sugar_heading">
                {title}
                <span
                  style={styles.close}
                  onClick={() => {
                    handleSPIClose(false);
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: modal_close_icon }} />
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={styles.modalBody}>
              <div className="popup_boxed">
                <div className="form-group drop_form data_drop">
                  <label htmlFor="city">City</label>
                  <Select
                    onChange={handleCityChange}
                    options={cityOptions}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    className="selector"
                    defaultValue={{ label: 'National', value: 'national' }}
                    // isMulti
                    isClearable={false}
                  />
                </div>
                {avgSelector ? (
                  <div className="form-group drop_form data_drop">
                    <label htmlFor="average">Average</label>
                    <Select
                      onChange={handleAverageChange}
                      options={average}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      className="selector"
                      defaultValue={{ label: 'Average', value: 'average' }}
                      // isMulti
                      isClearable={false}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
              <Button variant="primary" className="Applybtn" onClick={ApplySPI}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
    </>
  );
}

const styles = {
  modalBackdrop: {
    zIndex: '999999',
    borderRadius: '20px',
  },
  modalHeader: {
    padding: '15px 8px',
    letterSpacing: '0.3px',
    fontWeight: '700',
    justifyContent: 'center',
    color: '#3d3d3d',
    borderBottom: 'var(--light-border)',
  },
  close: {
    cursor: 'pointer',
    float: 'right',
    color: '#3d3d3d',
  },
  modalTitle: {
    fontWeight: '700',
    color: '#3d3d3d',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  modalBody: {
    background: '#fff',
    padding: '10px 15px 10px',
  },
  sessionAlertContent: {
    position: 'relative',
  },
  para: {
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
  },
  iconSpan: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
    color: '#ffff',
    margin: '0',
  },
  buttonOK: {
    position: 'absolute',
    right: '15px',
    width: '70px',
    border: 'none',
    background: '#3969d5',
    textTransform: 'uppercase',
    color: '#fff',
    fontWeight: '600',
    borderRadius: '7px',
    padding: '4px 10px',
  },
  /* excel: {
    cursor: 'pointer',
    float: 'right',
  }, */
};

export default Index;
